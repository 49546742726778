import Toggle from "./Toggle";
import List from "@mui/material/List";
import { makeStyles } from "@mui/styles";
import { Fragment, useState } from "react";
import ReferFriend from "Pages/ReferFriend";
import { styled } from "@mui/material/styles";
import { DrawerHeader } from "./DrawerHeader";
import { PowerSettingsNew } from "@mui/icons-material";
import { clearAppTokensAndRedirect } from "core/utils";
import { NavLink, useLocation } from "react-router-dom";
import glassIcon from "../assets/images/icon/glass.svg";
import logo from "core/assets/images/logo/intellistocks.png";
import { useUserInfo } from "Pages/UserPages/Profile/api_actions";
import { adminSidebarList, sidebarList } from "./Sidebar.constant";
import ReferFriendIcon from "../assets/images/icon/refer_friend.svg";
import { Divider, Drawer as MuiDrawer, Grid, ListItem, ListItemText } from "@mui/material";

const useStyles = makeStyles({
  paper: {
    background: "#000",
  },
  divider: {
    background: "#fff",
  },
  listItem: {
    "&:hover": {
      background: "#5c5c5c",
    },
  },
});

const Sidebar = ({ open, drawerWidth, handleDrawerOpen, handleDrawerClose }) => {
  const [openReferFriend, setOpenReferFriend] = useState(false);
  const { pathname } = useLocation();

  const handleClickOpenReferFriend = () => setOpenReferFriend(true);
  const handleClose = () => setOpenReferFriend(false);

  const { data: userInfo } = useUserInfo();

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(7)} + 6px)`,
    },
  });

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const classes = useStyles();
  const sideList = sidebarList();
  const adminList = adminSidebarList();

  return (
    <>
      <ReferFriend open={openReferFriend} close={handleClose} />
      <Drawer variant="permanent" open={open} classes={{ paper: classes.paper }}>
        <DrawerHeader>
          <img style={{ width: "75%", height: "auto", margin: "auto" }} src={logo} alt="logo" />
        </DrawerHeader>
        <Divider className={classes.divider} />
        <List>
          {pathname && !pathname.includes("/admin-dashboard") ? (
            <>
              {userInfo?.data?.is_staff && (
                <ListItem button className={classes.listItem} component={NavLink} to={"/admin-dashboard/users"}>
                  <img src={glassIcon} height={10} alt="" style={{ color: "#fff", marginLeft: 5, marginRight: 25 }} />
                  <ListItemText primary="Admin Dashboard" style={{ color: "#fff" }} />
                </ListItem>
              )}
              {sideList.map(({ id, label, link, icon }) => (
                <Fragment key={id}>
                  <ListItem
                    button
                    component={NavLink}
                    to={link !== "/" && link}
                    className={classes.listItem}
                    activeStyle={{ backgroundColor: "#2c915a" }}
                    sx={{
                      borderRadius: 18,
                      backgroundColor: id === 2 && pathname === "/idea-generation" ? "#2c915a" : "",
                    }}
                  >
                    <img src={icon} width="20px" height="20px" alt="icon" style={{ marginLeft: 5, marginRight: 25 }} />

                    <ListItemText primary={label} style={{ color: "#fff" }} />
                    {label === "Watch List" ? (
                      <Grid
                        width="30px"
                        color="#000"
                        bgcolor="#fff"
                        fontSize="1rem"
                        fontWeight="bold"
                        borderRadius="50%"
                        textAlign="center"
                      >
                        {userInfo?.data.watch_list_tickers_count}
                      </Grid>
                    ) : null}
                  </ListItem>
                </Fragment>
              ))}
              <ListItem button onClick={handleClickOpenReferFriend} className={classes.listItem}>
                <img
                  alt=""
                  height={20}
                  src={ReferFriendIcon}
                  style={{ color: "#fff", marginLeft: 5, marginRight: 25 }}
                />
                <ListItemText primary="Refer a Friend" style={{ color: "#fff" }} />
              </ListItem>
              <Grid width={open ? 230 : 61} position="fixed" bottom={20}>
                <ListItem button onClick={clearAppTokensAndRedirect} className={classes.listItem}>
                  <PowerSettingsNew style={{ color: "#fff", marginLeft: 5, marginRight: 25 }} />
                  <ListItemText primary={open ? "Logout" : ""} style={{ color: "#fff" }} />
                </ListItem>
              </Grid>
            </>
          ) : (
            <>
              {userInfo?.data?.is_staff &&
                adminList.map(({ id, label, link, icon }) => (
                  <Fragment key={id}>
                    <ListItem
                      button
                      component={NavLink}
                      to={link !== "/" && link}
                      sx={{ borderRadius: 18 }}
                      className={classes.listItem}
                      activeStyle={{ backgroundColor: "#2c915a" }}
                    >
                      <img
                        src={icon}
                        width="20px"
                        height="20px"
                        alt="icon"
                        style={{ marginLeft: 5, marginRight: 25 }}
                      />

                      <ListItemText primary={label} style={{ color: "#fff" }} />
                    </ListItem>
                  </Fragment>
                ))}
            </>
          )}
        </List>
        <div style={{ position: "absolute", top: "50%", right: 16 }}>
          <Toggle open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
        </div>
      </Drawer>
    </>
  );
};

export default Sidebar;
