import {
  LOGIN_PAGE,
  DISCLAIMER,
  LANDING_PAGE,
  SIGN_UP_PAGE,
  RESET_PASSWORD,
  PRIVACY_POLICY,
  COOKIES_POLICY,
  FORGOT_PASSWORD,
  TRENDING_PUBLIC,
  PASSWORD_CHANGED,
  EMAIL_CONFIRMATION,
  ACCOUNT_ACTIVATION,
  SUBSCRIPTION_PLANS,
  TERMS_AND_CONDITIONS,
} from "./constants";
import { lazy } from "react";
import { Route } from "react-router-dom";

/**
 *
 * @author Muhammad Raheel
 * @category Routes
 * @created Mar 14, 2022
 * @description Array of public routes which serves to AppMain, To define new public route add object in List
 */

export const publicRoutes: Array<publicRouteTypes> = [
  {
    path: LOGIN_PAGE,
    component: lazy(() => import("../UserPages/Login")),
  },
  {
    path: SIGN_UP_PAGE,
    component: lazy(() => import("../UserPages/SignUp")),
  },
  {
    path: RESET_PASSWORD,
    component: lazy(() => import("../UserPages/ResetPassword")),
  },
  {
    path: FORGOT_PASSWORD,
    component: lazy(() => import("../UserPages/ForgotPassword")),
  },
  {
    path: EMAIL_CONFIRMATION,
    component: lazy(() => import("../UserPages/EmailConfirmation")),
  },
  {
    path: ACCOUNT_ACTIVATION,
    component: lazy(() => import("../UserPages/AccountConfirmation")),
  },
  {
    path: PASSWORD_CHANGED,
    component: lazy(() => import("../UserPages/AccountResetSuccess")),
  },
  {
    path: LANDING_PAGE,
    component: lazy(() => import("../LandingPage")),
  },
  {
    path: TRENDING_PUBLIC,
    component: lazy(() => import("../TrendingPublic")),
  },
  {
    path: TERMS_AND_CONDITIONS,
    component: lazy(() => import("../Legal/TermsAndConditions")),
  },
  {
    path: SUBSCRIPTION_PLANS,
    component: lazy(() => import("../SubscriptionPlans")),
  },
  {
    path: PRIVACY_POLICY,
    component: lazy(() => import("../Legal/PrivacyPolicty")),
  },
  {
    path: DISCLAIMER,
    component: lazy(() => import("../Legal/Disclaimer")),
  },
  {
    path: COOKIES_POLICY,
    component: lazy(() => import("../Legal/CookiesPolicy")),
  },
];

/**
 *
 * @author Muhammad Raheel
 * @category Routes
 * @created Mar 14, 2022
 * @description type for defining object in public routes list
 */

export type publicRouteTypes = {
  path: string;
  component: any;
};

/**
 * @ignore
 */

export const PublicRoute = ({ component: Component, ...rest }: any) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
