import moment from "moment";
import { FC, useEffect } from "react";
import SideDrawer from "./SideDrawer";
import Avatar from "@mui/material/Avatar";
import NavBarBtnLink from "./NavbarLInks";
import Toolbar from "@mui/material/Toolbar";
import { getAccessToken } from "core/utils";
import RightSideMenu from "./RightSideMenu";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import deepOrange from "@mui/material/colors/deepOrange";
import { useHistory, useLocation } from "react-router-dom";
import { AccountBalanceWallet } from "@mui/icons-material";
import ThemeModeSwitcher from "core/components/themeModeSwitcher";
import { CircularProgress, Grid, Tooltip, useMediaQuery } from "@mui/material";
import { useUserBalance, useUserInfo } from "../../Pages/UserPages/Profile/api_actions";
import bg from "core/assets/images/icon/landing_bg.png";
import lightLogo from "core/assets/images/icon/white_logo.svg";

const Header: FC<HeaderPropTypes> = ({ open, drawerWidth, publicRoute = false }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { data, isLoading, isError, refetch: getUserInfo } = useUserInfo();
  const { data: balance, isLoading: balLoading, isError: balError, refetch: getUserBalance } = useUserBalance();

  const isMediumScreen = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  useEffect(() => {
    if (publicRoute) {
      return;
    }

    getUserInfo();
    getUserBalance();
    // eslint-disable-next-line
  }, []);

  const AppBar: any = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }: any) => ({
    width: publicRoute ? "" : "97%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const renderInitials = (d: any) => {
    const firstName = d?.data?.first_name || "";
    const lastName = d?.data?.last_name || "";

    let FI = "";
    let LI = "";

    if (firstName && firstName.length > 0) {
      FI = firstName.slice(0, 1);
    }
    if (lastName && lastName.length > 0) {
      LI = lastName.slice(0, 1);
    }

    return (FI + LI).toUpperCase();
  };

  const TrialMsg = ({ expiry }: any) => {
    const date = `${moment(expiry)}`;
    return (
      <Tooltip
        title={`You're using Premium plan in trial mode,
      after trial period ends on ${date} plan will be automatically subscribed`}
      >
        <Typography style={{ fontSize: 15 }}>
          Subscription status:<strong style={{ color: "red" }}>Trial</strong>
        </Typography>
      </Tooltip>
    );
  };
  const CancelMsg = ({ expiry }: any) => {
    const date = `${moment(expiry)}`;
    return (
      <Tooltip title={`You have canceled your subscription, you'll be moved to Basic Plan after ${date} automatically`}>
        <Typography style={{ fontSize: 15 }}>
          Subscription status:<strong style={{ color: "red" }}>Cancelled</strong>
        </Typography>
      </Tooltip>
    );
  };

  const renderSideDrawer = (() => {
    if (pathname === "/subscription-plans" || pathname === "/trending-public") return;

    if (!isMediumScreen) {
      return (
        <Grid ml={-2}>
          <SideDrawer open={open} userInfo={data?.data} />
        </Grid>
      );
    }

    return null;
  })();

  return (
    <AppBar
      position="fixed"
      open={open}
      color="inherit"
      elevation={0}
      sx={{
        padding: 1,
        backgroundImage:
          pathname === "/subscription-plans" || pathname === "/trending-public" || pathname === "/" ? `url(${bg})` : "",
      }}
    >
      <Toolbar sx={{ paddingBottom: "6px" }} variant="dense">
        {renderSideDrawer}
        <Grid ml={open || !isMediumScreen ? 1 : 7}>
          <NavBarBtnLink />
        </Grid>
        <div style={{ flexGrow: 1 }} />
        <Grid container>
          {pathname !== "/subscription-plans" && pathname !== "/trending-public" && !isMediumScreen ? (
            <div
              style={{
                marginLeft: "auto",
              }}
            >
              <RightSideMenu
                data={data}
                blncError={balError}
                blncLoading={balLoading}
                balance={balance?.data?.balance}
              />
            </div>
          ) : (
            (pathname === "/subscription-plans" || pathname === "/trending-public" || pathname === "/") && (
              <Grid container justifyContent="space-between">
                <Grid item md={4} display="flex" alignItems="center">
                  <img src={lightLogo} alt="lgt_icon" height={40} />
                </Grid>

                {isMediumScreen ? (
                  <Grid item md={8} display="flex" justifyContent="center" alignItems="center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        className="cursor-pointer"
                        style={{ marginRight: 30, color: "white" }}
                        onClick={() => history.push("/")}
                      >
                        Home
                      </div>

                      <div
                        className="cursor-pointer"
                        style={{ marginRight: 30, color: "white" }}
                        onClick={() => history.push("/subscription-plans")}
                      >
                        Pricing
                      </div>

                      <div
                        className="cursor-pointer"
                        style={{ marginRight: 30, color: "white" }}
                        onClick={() => history.push("/trending-public")}
                      >
                        Trending
                      </div>
                    </div>
                  </Grid>
                ) : (
                  <Grid item md={8} display="flex" justifyContent="flex-end" alignItems="center">
                    <RightSideMenu
                      menu
                      data={data}
                      blncError={balError}
                      blncLoading={balLoading}
                      balance={balance?.data?.balance}
                    />
                  </Grid>
                )}
              </Grid>
            )
          )}
        </Grid>
        {isMediumScreen ? (
          <Grid item md={4} display="flex" alignItems="center" justifyContent="flex-end">
            <ThemeModeSwitcher />
            {getAccessToken() && (
              <span>
                {!isLoading ? (
                  !isError && (
                    <span
                      style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
                      onClick={() => history.push("/user-profile")}
                    >
                      <span style={{ marginRight: "1rem", marginLeft: "15px" }}>
                        {data?.data?.subscription && data?.data?.subscription?.status === "trialing" ? (
                          <TrialMsg expiry={data?.data?.subscription?.current_period_end} />
                        ) : null}
                      </span>
                      <span style={{ marginRight: "1rem", marginLeft: "15px" }}>
                        {data?.data?.subscription && data?.data?.subscription?.status === "canceled" ? (
                          <CancelMsg expiry={data?.data?.subscription?.current_period_end} />
                        ) : null}
                      </span>
                      {[null, undefined].includes(data?.data?.profile_image) ? (
                        <Avatar
                          sx={{ bgcolor: deepOrange[500] }}
                          style={{ marginRight: 10 }}
                          children={renderInitials(data)}
                        />
                      ) : (
                        <Avatar style={{ marginRight: 10 }} alt="profile_pic" src={data?.data?.profile_image} />
                      )}

                      <Typography noWrap>{`${data?.data?.first_name} ${data?.data?.last_name}`}</Typography>

                      <span style={{ display: "flex", alignItems: "center", marginLeft: 15 }}>
                        <AccountBalanceWallet style={{ marginRight: 5 }} />{" "}
                        {!balLoading && !balError && "$" + balance?.data?.balance}
                      </span>
                    </span>
                  )
                ) : (
                  <Grid mt={0.5} ml={5}>
                    <CircularProgress size={"1.2rem"} />
                  </Grid>
                )}
              </span>
            )}
          </Grid>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

type HeaderPropTypes = { open: boolean; drawerWidth: number; publicRoute: boolean };

export default Header;
