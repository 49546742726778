import * as React from "react";
import Grid from "@mui/material/Grid";
import LowerHeader from "./MuiHeader";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import ThemeModeSwitcher from "core/components/themeModeSwitcher";
import MainBg from "../../../core/assets/images/icon/landing_bg.png";
import LogoHere from "../../../core/assets/images/icon/white_logo.svg";
import { IconButton, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";

const Header = () => {
  const theme = useTheme();
  const history = useHistory();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchor, setAnchor] = React.useState(null);
  const open = Boolean(anchor);

  const handleMenu = (event: any) => setAnchor(event.currentTarget);

  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12} style={styles.paperContainer}>
        <AppBar position="static" color={"transparent"} elevation={0}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item ml={isMediumScreen ? -4 : ""}>
              <img src={LogoHere} width={isMediumScreen ? 200 : 300} alt="intellistocks-logo" />
            </Grid>
            <Grid item sx={{ display: { xl: "block", lg: "block", md: "none", sm: "none", xs: "none" } }}>
              <Button
                variant="text"
                onClick={() => history.push("/")}
                style={{ color: "#fff", textTransform: "none", marginRight: "1rem" }}
              >
                Home
              </Button>
              <Button
                variant="text"
                onClick={() => history.push("/subscription-plans")}
                style={{ color: "#fff", textTransform: "none", marginRight: "1rem" }}
              >
                Pricing
              </Button>
              <Button
                variant="text"
                onClick={() => history.push("/trending-public")}
                style={{ color: "#fff", textTransform: "none", marginRight: "1rem" }}
              >
                Trending
              </Button>
            </Grid>
            <Grid item sx={{ display: { xl: "block", lg: "block", md: "none", sm: "none", xs: "none" } }}>
              <Grid display="flex">
                <Button sx={{ color: "white" }} onClick={() => history.push("/login")}>
                  Login
                </Button>

                <Button sx={{ color: "white" }} onClick={() => history.push("/sign-up")}>
                  Signup
                </Button>
                <Grid mx={2} />
                <ThemeModeSwitcher />
              </Grid>
            </Grid>
            <Grid item mr={-5} sx={{ display: { xl: "none", lg: "none" } }}>
              <Grid display="flex">
                <IconButton edge="start" aria-label="menu" onClick={handleMenu} style={{ color: "#fff" }}>
                  <MenuIcon />
                </IconButton>

                <ThemeModeSwitcher />
              </Grid>
            </Grid>
          </Grid>
        </AppBar>

        <div style={{ marginTop: 100 }}>
          <LowerHeader />
        </div>
      </Grid>

      <Menu
        open={open}
        id="menu-appbar"
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            history.push("/");
            setAnchor(null);
          }}
        >
          <Typography variant="h6"> Home</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            history.push("/subscription-plans");
            setAnchor(null);
          }}
        >
          <Typography variant="h6"> Pricing </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            history.push("/trending-public");
            setAnchor(null);
          }}
        >
          <Typography variant="h6"> Trending</Typography>
        </MenuItem>

        <MenuItem onClick={() => history.push("/login")}>
          <Typography variant="h6"> Login</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            history.push("/trending");
            setAnchor(null);
          }}
        >
          <Typography variant="h6"> Signup </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Header;

const styles = {
  paperContainer: {
    minHeight: "60vh",
    backgroundSize: "cover",
    padding: "20px 70px 50px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${MainBg})`,
  },
};
