import { Link, LinkProps } from "@mui/material";
import { FC, ReactElement, ReactNode } from "react";

/**
 *
 * @author Muhammad Raheel
 * @category Components
 * @created Jan 13, 2022
 * @description MUI customized anchor element
 * @returns This function returns ReactElement
 */

export const MuiLink: FC<MuiLinkPropTypes> = ({ href = "#", label, color = "primary", underline, ...restProps }) => (
  <Link href={href} color={color} underline={underline} {...restProps}>
    {label}
  </Link>
);

/**
 *
 * @category Types
 * @author Muhammad Raheel
 * @created Jan 13, 2022
 * @link LinkProps API Docs: https://mui.com/api/link/
 */
export type MuiLinkPropTypes = {
  to?: string;
  href?: string;
  component?: ReactElement | ReactNode;
  label?: string | ReactElement | ReactNode;
} & LinkProps;
