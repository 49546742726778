import { TFunctionArg } from "types/global";

export const intervalList = (t: TFunctionArg) => [
  { label: "15 Min", value: "15_mint" },
  { label: "1 Hour", value: "1_hour" },
  { label: "6 Hour", value: "6_hour" },
  { label: "1 Day", value: "1_day" },
  { label: "1 Week", value: "1_week" },
  { label: "1 Month", value: "1_month" },
];

export const chartTypesList = (t: TFunctionArg) => [
  { label: "Area Chart", value: "area" },
  { label: "Candlestick Chart", value: "candlestick" },
  { label: "Column Chart", value: "column" },
  { label: "Jump Line Chart", value: "jumpLine" },
  { label: "Line Chart", value: "line" },
  { label: "Marker Chart", value: "marker" },
  { label: "OHLC Chart", value: "ohlc" },
  { label: "Range Area Chart", value: "rangeArea" },
  { label: "Range Column Chart", value: "rangeColumn" },
  { label: "Range Spline Area Chart", value: "rangeSplineArea" },
  { label: "Range Step Area Chart", value: "rangeStepArea" },
  { label: "Spline Chart", value: "spline" },
  { label: "Spline Area Chart", value: "splineArea" },
  { label: "Step Area Chart", value: "stepArea" },
  { label: "Step Line Chart", value: "stepLine" },
  { label: "Stick Chart", value: "stick" },
];

export const indicatorChartList = (t: TFunctionArg) => [
  { value: "area", label: t("area") },
  { value: "column", label: t("column") },
  { value: "jump-line", label: t("jump_line") },
  { value: "line", label: t("linechart") },
  { value: "marker", label: t("market") },
  { value: "spline", label: t("spline") },
  { value: "spline-area", label: t("spline_area") },
  { value: "step-area", label: t("step_area") },
  { value: "step-line", label: t("step_line") },
  { value: "stick", label: t("stick") },
];

export const movingAverageList = (t: TFunctionArg) => [
  { value: "sma", label: t("sma") },
  { value: "ema", label: t("exponential_moving_average") },
];
