import Header from "./Header";
import Sidebar from "./Sidebar";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useAppSelector } from "redux_config/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FC, useState, useLayoutEffect, ReactNode } from "react";

const Layout: FC<ReactNode & { publicRoute?: boolean }> = ({ children, publicRoute = false }) => {
  const [open, setOpen] = useState<boolean>(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const { mode } = useAppSelector((state) => state.theme);

  const isLargeScreen = useMediaQuery((theme: any) => theme.breakpoints.up("md"));

  useLayoutEffect(() => {
    if (!isLargeScreen && open) {
      setOpen(false);
    } else {
      if (isLargeScreen && !open) {
        setOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [isLargeScreen]);

  const drawerWidth = 230;

  return (
    <Box style={{ backgroundColor: "gray" }} sx={{ display: "flex", overflowX: "hidden" }}>
      <CssBaseline />
      <Header publicRoute open={open} drawerWidth={drawerWidth} />
      {!publicRoute && isLargeScreen ? (
        <Sidebar
          open={open}
          drawerWidth={drawerWidth}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      ) : null}
      <Paper
        component="main"
        sx={{
          p: 3,
          flexGrow: 1,
          height: "100vh",
          overflowX: "hidden",
          backgroundColor: mode === "light" ? "#f3f3f3" : "black",
        }}
      >
        <br />
        <div style={{ marginTop: 5 }}>{children}</div>
      </Paper>
    </Box>
  );
};

export default Layout;
