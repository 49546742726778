import { useFormik } from "formik";
import Card from "react-credit-cards";
import { OnChangeEvent } from "types/global";
import { COUNTRIES } from "core/utils/constants";
import "react-credit-cards/es/styles-compiled.css";
import { useAppSelector } from "redux_config/hooks";
import { MuiButton, MuiInput } from "core/components";
import { FC, useEffect, useRef, useState } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useAddUserPaymentInfo, useEditUserPaymentInfo } from "Pages/UserPages/Profile/api_actions";
import { formatCreditCardNumber, formatCVC, formatExpirationDate, formatFormData } from "core/utils";
import { initialValues, InternalStateProps, validationSchema, validationSchemaEdit } from "./schema";

export const CreditCard: FC<CreditCardProps> = ({ handleClose, isEdit, cardInfo }) => {
  const { mode } = useAppSelector((state) => state.theme);
  const [state, setState] = useState<InternalStateProps>(initialValues);
  const [cardDetail, setcardDetail] = useState({
    number: "",
    country: "",
    exp_month: "",
    exp_year: "",
    address_country: "",
  });
  const { mutate, isLoading } = useAddUserPaymentInfo(handleClose);

  const formik = useFormik({
    initialValues: {
      cvc: "",
      name: "",
      number: "",
      issuer: "",
      address_1: "",
      address_2: "",
      formData: null,
      focused: undefined,
      country: isEdit ? cardInfo?.country : "",
      expiry: isEdit ? cardInfo?.exp_month + "/" + cardInfo?.exp_year : "",
    },
    validationSchema: isEdit ? validationSchemaEdit : validationSchema,

    onSubmit: (values) => {
      if (isEdit) {
        const value = { card_id: "", address_country: "", exp_month: "", exp_year: "", country: "" };
        value.card_id = cardInfo?.card_id || "";
        value.address_country = cardDetail?.country;
        value.country = state.country || "";
        value.exp_month = values?.expiry.split("/")[0];
        value.exp_year = `20${values?.expiry.split("/")[1]}`;
        mutateEdit(value);
      } else {
        mutate({
          type: "card",
          card: {
            cvc: state.cvc,
            number: state.number,
            exp_year: state.expiry.split("/")[1],
            exp_month: state.expiry.split("/")[0],
          },
          billing_details: {
            name: state.name,
            address: {
              country: state.country,
              line1: state.address_1,
              line2: state.address_2,
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      setcardDetail({
        number: cardInfo?.card_id || "",
        country: cardInfo?.country || "",
        exp_month: cardInfo?.exp_month || "",
        exp_year: cardInfo?.exp_year || "",
        address_country: cardInfo?.address_country || "",
      });
      setState({ ...state, country: cardInfo?.country, expiry: cardInfo?.exp_month + "/" + cardInfo?.exp_year });
    }
    // eslint-disable-next-line
  }, [cardInfo]);

  const { mutate: mutateEdit, isLoading: editLoading } = useEditUserPaymentInfo(handleClose);

  const form = useRef(null);

  const handleCallback = (e: any, isValid: boolean) => {
    if (isValid) {
      setState({ ...state, issuer: e?.issuer });
    }
  };

  const handleInputFocus = (e: OnChangeEvent) => {
    setState({
      ...state,
      focused: e.target.name,
    });
  };

  const handleInputChange = ({ target }: OnChangeEvent) => {
    if (isEdit) {
      if (target.name === "expiry") {
        target.value = formatExpirationDate(target.value);
      }
    } else {
      if (target.name === "number") {
        target.value = formatCreditCardNumber(target.value);
      } else if (target.name === "expiry") {
        target.value = formatExpirationDate(target.value);
      } else if (target.name === "cvc") {
        target.value = formatCVC(target.value);
      }
    }

    setState({ ...state, [target.name]: target.value });
  };

  const { name, number, expiry, cvc, focused, formData } = state;

  const editStateNumber =
    cardInfo?.last4?.length > 16 ? `000000000000000${cardInfo?.last4}` : `000000000000${cardInfo?.last4}`;

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={12} lg={12} item>
        <Card
          cvc={cvc}
          expiry={expiry}
          focused={focused}
          callback={handleCallback}
          number={isEdit ? editStateNumber : number}
          name={isEdit ? cardInfo?.card_holder_name : name}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <form ref={form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {isEdit ? (
              <>
                <Grid item xs={12} md={6}>
                  <div className="col-6">
                    <MuiInput
                      required
                      type="tel"
                      name="expiry"
                      label="Valid Thru"
                      placeholder="MM/YY"
                      pattern="\d\d/\d\d"
                      value={state?.expiry}
                      className="form-control"
                      error={!!formik.touched.number && !!formik.errors.number}
                      helperText={formik.touched.number && formik.errors.number}
                      onChange={(evt: any) => {
                        formik.handleChange(evt);
                        handleInputChange(evt);
                      }}
                      //@ts-ignore
                      onFocus={handleInputFocus}
                    />
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    color="primary"
                    disabled={isEdit}
                    variant="outlined"
                    sx={mode === "dark" ? { backgroundColor: "#1e1e1e" } : {}}
                    error={!!formik.touched.country && !!formik.errors.country}
                  >
                    <InputLabel>Country</InputLabel>
                    <Select
                      name="country"
                      label="Country"
                      value={isEdit ? cardInfo?.address_country : state.country}
                      onChange={(evt: any) => {
                        formik.handleChange(evt);
                        handleInputChange(evt);
                      }}
                    >
                      {COUNTRIES.map(({ Flag, value, label }, index) => (
                        <MenuItem value={value} key={index}>
                          <Grid display="flex" alignItems="center" columnGap={2}>
                            {Flag && <Flag />} {label}
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                    {!!formik.touched.number && !!formik.errors.number && (
                      <FormHelperText>{formik.touched.number && formik.errors.number}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={12} xs={12}>
                  <MuiInput
                    required
                    type="tel"
                    name="number"
                    label="Card Number"
                    pattern="[\d| ]{16,22}"
                    className="form-control"
                    placeholder="Card Number"
                    error={!!formik.touched.number && !!formik.errors.number}
                    helperText={formik.touched.number && formik.errors.number}
                    onChange={(evt: any) => {
                      formik.handleChange(evt);
                      handleInputChange(evt);
                    }}
                    //@ts-ignore
                    onFocus={handleInputFocus}
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <MuiInput
                    required
                    type="text"
                    name="name"
                    label="Name"
                    placeholder="Name"
                    className="form-control"
                    error={!!formik.touched.name && !!formik.errors.name}
                    helperText={formik.touched.name && formik.errors.name}
                    onChange={(evt: any) => {
                      formik.handleChange(evt);
                      handleInputChange(evt);
                    }}
                    //@ts-ignore
                    onFocus={handleInputFocus}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <div className="col-6">
                    <MuiInput
                      type="tel"
                      name="expiry"
                      label="Valid Thru"
                      placeholder="MM/YY"
                      pattern="\d\d/\d\d"
                      className="form-control"
                      error={!!formik.touched.expiry && !!formik.errors.expiry}
                      helperText={formik.touched.expiry && formik.errors.expiry}
                      required
                      onChange={(evt: any) => {
                        formik.handleChange(evt);
                        handleInputChange(evt);
                      }}
                      //@ts-ignore
                      onFocus={handleInputFocus}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <div className="col-6">
                    <MuiInput
                      required
                      type="tel"
                      name="cvc"
                      label="CVC"
                      placeholder="CVC"
                      pattern="\d{3,4}"
                      className="form-control"
                      error={!!formik.touched.cvc && !!formik.errors.cvc}
                      helperText={formik.touched.cvc && formik.errors.cvc}
                      onChange={(evt: any) => {
                        formik.handleChange(evt);
                        handleInputChange(evt);
                      }}
                      //@ts-ignore
                      onFocus={handleInputFocus}
                    />
                  </div>
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={mode === "dark" ? { backgroundColor: "#1e1e1e" } : {}}
                    error={!!formik.touched.country && !!formik.errors.country}
                  >
                    <InputLabel>Country</InputLabel>
                    <Select
                      name="country"
                      label="Country"
                      value={state.country}
                      onChange={(evt: any) => {
                        formik.handleChange(evt);
                        handleInputChange(evt);
                      }}
                    >
                      {COUNTRIES.map(({ Flag, value, label }, index) => (
                        <MenuItem value={value} key={index}>
                          <Grid display="flex" alignItems="center" columnGap={2}>
                            {Flag && <Flag />} {label}
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                    {!!formik.touched.number && !!formik.errors.number && (
                      <FormHelperText>{formik.touched.number && formik.errors.number}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={12} xs={12}>
                  <MuiInput
                    name="address_1"
                    onChange={(evt: any) => {
                      formik.handleChange(evt);
                      handleInputChange(evt);
                    }}
                    className="form-control"
                    placeholder="Address 1"
                    label="Address 1"
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <MuiInput
                    name="address_2"
                    onChange={(evt: any) => {
                      formik.handleChange(evt);
                      handleInputChange(evt);
                    }}
                    className="form-control"
                    placeholder="Address 2"
                    label="Address 2"
                  />
                </Grid>
              </>
            )}

            <Grid item md={12} xs={12}>
              <MuiButton
                fullWidth
                role="submit"
                type="submit"
                color="primary"
                variant="contained"
                disabled={isLoading}
                loading={isEdit ? editLoading : isLoading}
                title={isEdit ? "Update Payment" : "Submit"}
                label={isEdit ? "Update Payment" : "Submit"}
              />
            </Grid>
          </Grid>
        </form>
      </Grid>

      {formData && (
        <div className="App-highlight">
          {formatFormData(formData).map((d, i) => (
            <div key={i}>{d}</div>
          ))}
        </div>
      )}
    </Grid>
  );
};

type CreditCardProps = {
  handleClose: VoidFunction;
  isEdit?: boolean;
  cardInfo?: {
    last4: any;
    card_holder_name: any;
    card_id: string | undefined;
    country: string | undefined;
    exp_year: string | undefined;
    exp_month: string | undefined;
    address_country: string | undefined;
  };
};

export default CreditCard;
