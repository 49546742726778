import Faqs from "./Faqs";
import About from "./About";
import Footer from "./Footer";
import Header from "./Header";
import Product from "./Product";
import React, { FC } from "react";
import ProductsDesc from "./ProductDesc";
// import { Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
const Introduction = React.lazy(() => import("./Introduction"));

const LandingPage: FC = () => {
  return (
    <div>
      <CssBaseline />
      <Header />
      <br />
      <Introduction />
      <br />
      <Product />
      <br />
      <ProductsDesc />
      <br />
      <About />
      <br />
      <Faqs />
      <br />
      <Footer />
    </div>
  );
};

export default LandingPage;
