import { createTheme } from "@mui/material/styles";

export const muiToggle = (mode: any) =>
  createTheme({
    palette: {
      primary: {
        main: "#217346",
        light: "#2c915a",
        dark: "#195e38",
        contrastText: "#fff",
      },
      secondary: {
        main: "#000",
        light: "#1A1A1A",
        dark: "#1A1A1A",
      },
      mode: mode,
    },
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: "5px !important",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "10px !important",
          },
        },
      },
    },
  });
