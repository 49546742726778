////////////////////////////////////////
export const LOGIN_PAGE = "/login";
export const SIGN_UP_PAGE = "/sign-up";
export const ACCOUNT_ACTIVATION = "/activate/:uid/:token";
export const RESET_PASSWORD = "/reset-password/:uid/:token";
export const FORGOT_PASSWORD = "/forgot-password";
export const PASSWORD_CHANGED = "/password-changed";
export const EMAIL_CONFIRMATION = "/email-confirmation";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const PRIVACY_POLICY = "/privacy-policy";
export const DISCLAIMER = "/disclaimer";
export const COOKIES_POLICY = "/cookies-policy";
////////////////////////////////////////
export const LANDING_PAGE = "/";
///////////////////////////////////////
export const TRENDING = "/trending";
export const WATCH_LIST = "/watch-list";
export const TO_THE_MOON = "/to-the-moon";
export const IDEA_GENERATION = "/idea-generation";

export const USER_PROFILE = "/user-profile";
export const UPGRADE_ACCOUNT = "/upgrade-account";
export const TRENDING_PUBLIC = "/trending-public";
export const REFER_A_FRIEND = "/refer-friend";
export const ADMIN_DASHBOARD = "/admin-dashboard";
export const SUBSCRIPTION_PLANS = "/subscription-plans";

export const ADMIN_DASHBOARD_USERS = "/admin-dashboard/users";
export const ADMIN_DASHBOARD_USERS_GROUPS = "/admin-dashboard/users-groups";
export const ADMIN_DASHBOARD_USERS_TO_GROUPS = "/admin-dashboard/users-to-groups";
export const ADMIN_DASHBOARD_GROUP_PERMISSIONs = "/admin-dashboard/group-permissions";
