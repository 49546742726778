import moment from "moment";
import jwtDecode from "jwt-decode";
import { TOKEN, REFRESH_TOKEN } from "core/utils/constants";
import { TwitterApiAuthParams } from "Pages/UserPages/SignUp/schema";
import { toast } from "react-toastify";
import Payment from "payment";
import { isArray, isObject } from "lodash";

const toastList = new Set();
const MAX_TOAST = 1;
//types written here
type JWTDecodeType = {
  exp: number;
  jti: string;
  token_type: string;
  user_id: number;
};
const intervals = [
  { label: "year", seconds: 31536000 },
  { label: "month", seconds: 2592000 },
  { label: "day", seconds: 86400 },
  { label: "hour", seconds: 3600 },
  { label: "minute", seconds: 60 },
  { label: "second", seconds: 1 },
];

// Password Strength Test Case Generator
export const PasswordStrengthTester = (password: string, setter: (num: number) => void) => {
  // eslint-disable-next-line no-useless-escape
  const specialSymbols = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  const hasLowerCase = /[a-z]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSymbol = specialSymbols.test(password);

  if (password.length > 7) {
    setter(1);
    if (hasLowerCase) {
      setter(1);
    }
    if (hasLowerCase && hasUpperCase) {
      setter(2);
    }
    if (hasLowerCase && hasUpperCase && hasNumber) {
      setter(3);
    }
    if (hasLowerCase && hasUpperCase && hasNumber && hasSymbol) {
      setter(4);
    }
  }

  return {
    hasLowerCase,
    hasUpperCase,
    hasNumber,
    hasSymbol,
  };
};

//get accessToken from localStorage
export const getAccessToken = () => {
  try {
    return localStorage.getItem(TOKEN);
  } catch (err) {
    console.log(err);
  }
};

//get accessToken from localStorage
export const getRefreshToken = () => {
  try {
    return localStorage.getItem(REFRESH_TOKEN);
  } catch (err) {
    console.log(err);
  }
};

//clears both refresh and access token also redirects to "/login"
export const clearAppTokensAndRedirect = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);

  window.location.href = window.location.origin + "/login";
};

// Refresh Token Expiry Checker
export const isRefreshTokenExpired = () => {
  const refresh_token = getRefreshToken();
  if (refresh_token) {
    try {
      const { exp }: JWTDecodeType = jwtDecode(refresh_token);

      if (moment(moment.unix(exp).utc().toISOString()).diff(moment.utc(moment.now()).toISOString()) < 0) {
        clearAppTokensAndRedirect();
      }
    } catch (err) {
      console.log(err);
    }
  }
};

// Table cell genration
export const getDescendantProp = (obj: any, desc: any) => {
  let arr = desc.split(".");
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
};

export const renderCell = (record: any, field: any, render: any) => {
  if (render) {
    return render(record);
  }

  return record[field];
};

// TwitterTokenRetrival
export const twitterTokenRetrival = (paramString: string) => {
  const x = paramString.split("&");
  const oauth_token = x[0].split("=")[1];
  const oauth_verifier = x[1].split("=")[1];

  let params: TwitterApiAuthParams = {
    oauth_token: oauth_token,
    oauth_verifier: oauth_verifier,
    provider: "twitter",
  };

  return { params };
};

/**
 * @name generateUniqueKey
 * @description
 * It generates the unique identifier for us to use in our application.
 * This will be used for giving unique key property
 * @returns {Generator<string>}
 */
function* generateUniqueKey(): Generator<string> {
  let i = 0;
  while (1) {
    yield `__${i++}__`;
  }
}

/**
 * @name uniqueIdIterator
 * @description
 * Iterator for `generateUniqueKey` function
 */
export const uniqueIdIterator = generateUniqueKey();

export const roundOf = (data: number, digit: number): number =>
  Math.round((data + Number.EPSILON) * Math.pow(10, digit)) / Math.pow(10, digit);
export const marketCapRound = (data: any) =>
  Number(parseInt(data) / 1000000).toLocaleString(undefined, {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });

export const timeSince = (date: any) => {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  const interval: any = intervals.find((i) => i.seconds < seconds);
  const count = Math.floor(seconds / interval.seconds);
  return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
};

export const ErrorMsg = (err: any) => {
  if (err.response?.data?.detail) {
    return err.response?.data?.detail;
  } else {
    return err.response?.data?.response || err.message;
  }
};
export const notify = (type: string, msg: string) => {
  if (toastList.size < MAX_TOAST) {
    let id: any;
    if (type === "success") {
      id = toast.success(msg, {
        onClose: () => toastList.delete(id),
      });
    } else if (type === "error") {
      id = toast.error(msg, {
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
};

export const roundFunc = (data: any, degit: any) =>
  Math.round((data + Number.EPSILON) * Math.pow(10, degit)) / Math.pow(10, degit);

/**
 * *Card Library for react-credit-cards functions
 */
function clearNumber(value: any = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value: any) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
        12,
        19
      )}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value: any) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  /* if (allValues?.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  } */

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value: any) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data: any) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export const displayErrorMessage = (err: any) => {
  const error = err.response?.data;

  if ((error && error?.length > 0) || Object.keys(error).length > 0) {
    if (isArray(error)) {
      // eslint-disable-next-line array-callback-return
      error.map((err: string) => {
        if (isObject(err)) {
          const keys = Object.keys(err);
          //@ts-ignore
          keys.map((item: string) => toast.error(err[item][0]));
        }
        toast.error(err);
      });
    }

    if (isObject(error)) {
      const keys = Object.keys(error);
      //@ts-ignore
      keys.map((item: string) => {
        //@ts-ignore
        if (error[item].length > 0 && isArray(error[item])) {
          //@ts-ignore
          return toast.error(error[item][0]);
        }
        //@ts-ignore
        return toast.error(error[item]);
      });
    }
  } else {
    toast.error(err.response?.data?.message || err.message);
  }
};
