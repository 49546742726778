import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "@mui/material";
import { FC } from "react";
import { TFunction } from "types/global";

export const MuiRadioGroup: FC<RadioGroupProps> = ({ children, ...restProps }) => (
  <FormControl>
    <RadioGroup {...restProps}>{children}</RadioGroup>
  </FormControl>
);

export const MuiRadio: FC<MuiRadioPropTypes & FormControlLabelProps> = ({ label = "", value, disabled }) => (
  <FormControlLabel value={value} disabled={disabled} control={<Radio />} label={label} />
);

export type MuiRadioPropTypes = {
  label?: TFunction | string;
} & RadioProps;
