import {
  Grid,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  DialogTitle,
  DialogProps,
  DialogContent,
  DialogActions,
  DialogTitleProps,
} from "@mui/material";
import { voidReturnType } from "types/global";
import { FC, ReactNode, forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MuiDialog: FC<DialogProps & DialogTitleProps & MuiDialogPropTypes> = ({
  title,
  actions,
  onClose,
  children,
  fullScreen,
  disableBackDropClickEscapeKey,
  ...restProps
}) => {
  const handleClose = (evt: any, reason: "backdropClick" | "escapeKeyDown") => {
    if (disableBackDropClickEscapeKey) {
      // eslint-disable-next-line
      if ((reason && reason == "backdropClick") || reason == "escapeKeyDown") {
        return;
      }
    }
    onClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      PaperProps={{ style: { borderRadius: 20 } }}
      onClose={(evt, reason) => handleClose(evt, reason)}
      {...restProps}
    >
      {fullScreen ? (
        <>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {title}
              </Typography>
              <IconButton onClick={onClose} sx={{ color: "#fff" }}>
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>{children}</DialogContent>
          <DialogActions>{actions}</DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <Grid display="flex" alignItems="center" justifyContent="space-between">
              <Grid>{title}</Grid>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>{actions}</DialogActions>
        </>
      )}
    </Dialog>
  );
};

type MuiDialogPropTypes = {
  onClose: voidReturnType;
  actions?: ReactNode | string;
  disableBackDropClickEscapeKey?: boolean;
};

export default MuiDialog;
