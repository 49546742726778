import { Grid, Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

const NavBarBtnLink = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const handleIdeaGenClick = () => push("/idea-generation");
  const handleDueDiligenceClick = () => push("/to-the-moon");

  const colorStyle = (path: string) => {
    if (pathname === path) {
      return { color: "#fff", bgcolor: "#217346" };
    }
  };

  if (pathname.includes("/to-the-moon") || pathname.includes("idea-generation")) {
    return (
      <Grid display="flex" columnGap={1}>
        <Typography
          noWrap
          padding={1.5}
          borderRadius={0.8}
          sx={{ cursor: "pointer" }}
          onClick={handleDueDiligenceClick}
          {...colorStyle("/to-the-moon")}
        >
          Due Diligence
        </Typography>

        <Typography
          noWrap
          padding={1.5}
          borderRadius={0.8}
          sx={{ cursor: "pointer" }}
          onClick={handleIdeaGenClick}
          {...colorStyle("/idea-generation")}
        >
          Idea Generation
        </Typography>
      </Grid>
    );
  }

  return <></>;
};

export default NavBarBtnLink;
