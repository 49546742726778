import "core/assets/css/global.css";
import AppMain from "GenerateRoutes";
import "react-toastify/dist/ReactToastify.css";
import { muiToggle } from "core/theme/muiTheme";
import { ToastContainer } from "react-toastify";
import { queryClient } from "QueryClient.config";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { useAppSelector } from "redux_config/hooks";
import { ThemeProvider } from "@mui/material/styles";
import { ReactQueryDevtools } from "react-query/devtools";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

/**
 *
 * @author Muhammad Raheel
 * @category Components
 * @created Mar 22, 2022
 * @modifiedBy Abdul Rauf
 * @description Main entry point for all the components, it get renders into DOM, all components are children of this component
 * @returns This function returns ReactElement
 */

export const App = () => {
  const { mode } = useAppSelector((state) => state.theme);

  useEffect(() => {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: true, // enable logs
    };
    const tagManagerArgs = {
      gtmId: "GTM-5G9ZCSN",
    };

    if (process.env.NODE_ENV === "production") {
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          //@ts-ignore
          ReactPixel.init("1223646291474528", options, advancedMatching);
          ReactPixel.pageView();
        });

      TagManager.initialize(tagManagerArgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        draggable
        limit={1}
        rtl={false}
        closeOnClick
        pauseOnHover
        autoClose={5000}
        pauseOnFocusLoss
        newestOnTop={false}
        position="top-center"
        hideProgressBar={false}
      />
      <ThemeProvider theme={muiToggle(mode)}>
        <BrowserRouter>
          <AppMain />
        </BrowserRouter>
      </ThemeProvider>
      <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
  );
};
