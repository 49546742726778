import { faqsList } from "./constants";
import { makeStyles } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import Questions from "../../../core/assets/images/icon/questions.svg";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

const useStyles = makeStyles((theme: any) => ({
  bg_landing: {
    width: "60%",
    minHeight: "400px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },

  more_about: {
    padding: "0px 200px",
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 20px",
    },
  },
  question_icon: {
    width: "75%",
    marginLeft: "10%",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "75%",
      marginLeft: "5%",
    },
  },
  questionSec: {
    padding: "0px 200px",
    marginTop: "-60px",
    marginBottom: "80px",

    [theme.breakpoints.down("1024")]: {
      marginTop: "580px",
      padding: "0px 50px",
    },
    [theme.breakpoints.down("720")]: {
      marginTop: "780px",
      padding: "0px",
    },
    [theme.breakpoints.between("1024", "1300")]: {
      padding: "0 50px",
    },
    [theme.breakpoints.down("500")]: {
      padding: "0px",
      marginTop: "950px",
    },
  },
}));

export function Faqs() {
  const classes = useStyles(false);

  return (
    <Box>
      <Grid id="faqs" item xs={12} sm={12} md={12} lg={12} className={classes.questionSec}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "20px" }}>
          <Typography fontSize="2rem" textAlign="center">
            Frequently Asked Questions
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} justifyContent="center">
            <img src={Questions} alt="about" className={classes.question_icon} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            {faqsList.map(({ id, question, answer }) => (
              <Accordion key={id} style={{ boxShadow: "none", margin: 10 }}>
                <AccordionSummary
                  expandIcon={<ExpandMore style={{ color: "#000" }} />}
                  style={{ color: "#000", backgroundColor: "#e8e8e8" }}
                >
                  {question}
                </AccordionSummary>
                <AccordionDetails>{answer}</AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Faqs;
