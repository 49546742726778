import { createSlice } from "@reduxjs/toolkit";

type IntitalState = {
  mode: any;
};

const currentMode = () => {
  //check for mode in localStorage if no mode set default mode to light in localStorage
  const mode = localStorage.getItem("mode");
  if (mode) {
    return mode;
  }
  localStorage.setItem("mode", "light");
  return "light";
};

const initialState: IntitalState = {
  mode: currentMode(),
};

export const themeSlicer = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleThemeMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleThemeMode } = themeSlicer.actions;

export default themeSlicer.reducer;
