import { MuiLink } from "core/components";
import { NavLink } from "react-router-dom";

export const faqsList = [
  {
    id: 1,
    question: "How do I use your data?",
    answer: `Using our alerts, you can use the data to protect against potential 
    downside risk due to a short squeeze or you could trade with the crowd.`,
  },
  {
    id: 2,
    question: "What data sources are being monitored?",
    answer: `Currently, we are monitoring the sub-reddit WallStreetBets. We will be 
    adding more sources soon.`,
  },
  {
    id: 3,
    question: "How frequently is the data updated?",
    answer: `The data is updated every 15 minutes. Each update has a lag of 
    approximately 30 minutes. For example, the update between 1:30 and 
    1:45 will reflect stats as of 1:15. This lag ensures that the probability of 
    restatements is nearly nil.`,
  },
  {
    id: 4,
    question: "How many stocks can I add in my watchlist?",
    answer: `For the paid plans, there is no cap on the number of stocks but there is a 
    minimum requirement of 3 stock. For the free plan, you can include a 
    maximum of 3 stocks in your watchlist.`,
  },
  {
    id: 5,
    question: "Can I import tickers from a csv to populate my watch list?",
    answer: `Yes. You can populate your watchlist using a csv file. You will have the 
    option to append to the existing watchlist or replace the existing with the 
    csv file.`,
  },
  {
    id: 6,
    question: "When is an alert generated?",
    answer: `An alert is generated when the number of mentions is a certain 
    percentage above the average mentions over the trailing 90 days. You can
    specify this threshold percentage for each ticker in your watchlist.`,
  },
  {
    id: 7,
    question: "How frequently are the alerts sent?",
    answer: "Alerts are sent once a day.",
  },
  {
    id: 8,
    question: "Can I snooze the alerts?",
    answer: "Yes, you can snooze each stock specific alert for 7 days.",
  },
  {
    id: 9,
    question: "Do you provide API access?",
    answer: "Yes, we provide API access under our enterprise plan.",
  },
  {
    id: 10,
    question: "How frequently is the API data updated?",
    answer: `There are two options: daily updates and 15-minute updates. Please send 
    us a message now to schedule a call to learn more about the enterprise 
    plan.`,
  },
  {
    id: 11,
    question: "What payment options are available?",
    answer: `You can pay with any credit card. Currently, we do not accept payment via
    bitcoins.`,
  },
  {
    id: 12,
    question: "What is the process for getting a trial account?",
    answer: (
      <>
        You can <MuiLink to="/sign-up" label="Sign Up" component={NavLink} style={{ color: "#188FF8" }} /> for a 14-day
        trial of our premium plan without entering a credit card.
      </>
    ),
  },
];

export const GET_SUBSCRIPTION_PLANS = "app/LandingPage/GET_SUBSCRIPTION_PLANS";
export const GET_SUBSCRIPTION_PLANS_SUCCESS = "app/LandingPage/GET_SUBSCRIPTION_PLANS_SUCCESS";
