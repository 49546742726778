import { FC } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

const PageLoader: FC = () => (
  <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
    <CircularProgress style={{ color: "#164B2E" }} size={50} thickness={3} />
  </Grid>
);

export default PageLoader;
