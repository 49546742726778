import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Card, CardContent, useMediaQuery } from "@mui/material";
import SMS from "../../../core/assets/images/icon/product_sms.svg";
import Email from "../../../core/assets/images/icon/product_email.svg";
import HistoricData from "../../../core/assets/images/icon/historic_data.svg";
import WatchListStock from "../../../core/assets/images/icon/watchlist_stock.svg";

const useStyles = makeStyles((theme: any) => {
  return {
    productSect: {
      padding: "0px 200px",
      marginBottom: "100px",
      [theme.breakpoints.down("md")]: {
        padding: "0px 40px",
      },
    },
  };
});

const products = [
  { icon: WatchListStock, title: "Maintain a stock watchlist" },
  { icon: HistoricData, title: "Stay abreast of trends" },
  { icon: SMS, title: "Get Alerts via <br />  SMS" },
  { icon: Email, title: "Get Alerts via <br /> Email" },
];

const Product = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid container display="flex" justifyContent="center" className={classes.productSect}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={isMobile ? { marginTop: "110px" } : { marginTop: "-110px" }}>
          <Typography textAlign={"center"} fontSize={30} margin={4} fontWeight={"bold"}>
            Our Product
          </Typography>
          <Grid container spacing={6}>
            {products.map((_p, i) => (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={i} justifyContent="center">
                <Card style={{ height: "100%", width: "100%" }} elevation={2}>
                  <CardContent
                    style={{
                      margin: 10,
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      padding: "16px 40px",
                      flexDirection: "column",
                    }}
                  >
                    <img src={_p.icon} width={60} height={100} alt="product" />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _p.title,
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Product;
