import glassIcon from "../assets/images/icon/glass.svg";
import profileIcon from "../assets/images/icon/profile.svg";
import userGroup from "../assets/images/icon/user_group.svg";
import trendingIcon from "../assets/images/icon/trending.svg";
import toTheMoonIcon from "../assets/images/icon/to_the_moon.svg";
import userToGroup from "../assets/images/icon/users_to_groups.svg";
import ArrowBackIcon from "../assets/images/icon/arrow-left-long.svg";
import userPermissions from "../assets/images/icon/user_permissions.svg";
import upgradeAccountIcon from "../assets/images/icon/upgrade_account.svg";

export const sidebarList: sidebarListType = () => [
  {
    id: 1,
    icon: trendingIcon,
    link: "/trending",
    label: "Trending",
  },
  {
    id: 2,
    icon: toTheMoonIcon,
    link: "/to-the-moon",
    label: "To the Moon",
  },
  {
    id: 3,
    icon: glassIcon,
    link: "/watch-list",
    label: "Watch List",
  },
  {
    id: 4,
    icon: upgradeAccountIcon,
    link: "/upgrade-account",
    label: "Upgrade Account",
  },
  {
    id: 5,
    icon: profileIcon,
    link: "/user-profile",
    label: "Profile",
  },
  // {
  //   id: 6,
  //   icon: referFriendIcon,
  //   link: "/refer-friend",
  //   label: "Refer a Friend",
  // },
];

type sidebarListType = () => {
  id: number;
  icon: any;
  link: string;
  label: string;
}[];

export const adminSidebarList: sidebarListType = () => [
  {
    id: 0,
    icon: profileIcon,
    link: "/admin-dashboard/users",
    label: "Users",
  },
  {
    id: 1,
    icon: userGroup,
    link: "/admin-dashboard/users-groups",
    label: "Users Groups",
  },
  {
    id: 2,
    icon: userToGroup,
    link: "/admin-dashboard/users-to-groups",
    label: "Users to Groups",
  },
  {
    id: 3,
    icon: userPermissions,
    link: "/admin-dashboard/group-permissions",
    label: "Group Permissions",
  },
  {
    id: 4,
    icon: ArrowBackIcon,
    link: "/trending",
    label: "Back",
  },
];
