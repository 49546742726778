import { Grid, Typography } from "@mui/material";
import { MuiButton, MuiDialog, MuiInput, MuiSelect } from "core/components";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { TFunctionArg } from "types/global";
import { indicatorChartList, movingAverageList } from "./constant";

const IndicatorDialog: FC<IndicatorPropTypes> = ({ t, open, onClose, indicators, setIndicators, setSelectValue }) => {
  const [stateValue, setStateValue] = useState<any>({});
  const handleChange = (evt: any) => {
    //@ts-ignore
    window.requestIdleCallback(() => {
      setStateValue((prev: any) => {
        return { ...prev, [evt.target.name]: evt.target.value };
      });
    });
  };

  const memoizedInputs = useMemo(() => {
    if (indicators?.["@type"]) {
      return indicators.defaults.map((item: any) => {
        if (item?.["@type"] === "number" && item?.["@name"] !== "plotIndex") {
          setStateValue((prev: any) => {
            return { ...prev, [item?.["@name"]]: item?.["@value"] };
          });
          return (
            <MuiInput
              type="number"
              name={item?.["@name"]}
              label={item?.["@name"]}
              onChange={handleChange}
              sx={{ marginBottom: 1.5 }}
              defaultValue={item?.["@value"]}
              key={item?.["@fieldName"]}
              value={stateValue[item?.["@name"]]}
            />
          );
        }
        if (item?.["@type"] === "array" || item?.["@type"] === "string") {
          let options = [];
          let optionDefault: any;
          if (item?.["@type"] === "string") {
            options = indicatorChartList(t);
            optionDefault = item?.["@value"];
          } else {
            options = movingAverageList(t);
            optionDefault = "ema";
          }

          setStateValue((prev: any) => {
            return { ...prev, [item?.["@name"]]: optionDefault };
          });

          return (
            <MuiSelect
              fieldKey="value"
              options={options}
              fieldLabel="label"
              name={item?.["@name"]}
              label={t(item?.["@name"])}
              style={{ marginBottom: 11 }}
              key={item?.["@fieldName"]}
              defaultValue={optionDefault}
              value={stateValue[item?.["@name"]]}
              onChange={handleChange}
            />
          );
        }
        return (
          <>
            <Typography variant="h6">{t("description")}</Typography>
            <Typography>{item?.description}</Typography>
            <Typography variant="h6">{t("parameters")}</Typography>
          </>
        );
      });
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicators]);

  const handleOnClose = () => {
    setSelectValue &&
      setSelectValue((prev: string[]) => {
        let prevState = prev;
        if (prevState.includes(indicators["@type"])) {
          prevState.pop();
        }
        return prevState;
      });
    onClose();
  };
  return (
    <MuiDialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={handleOnClose}
      title={indicators?.title}
      disableBackDropClickEscapeKey
      actions={
        <Grid container justifyContent="flex-end" spacing={1}>
          <Grid item>
            <MuiButton color="secondary" label={t("cancel")} onClick={handleOnClose} />
          </Grid>
          <Grid item>
            <MuiButton
              label={t("add_indicator")}
              onClick={() => {
                const arr = [];
                for (const key in stateValue) {
                  if (Object.prototype.hasOwnProperty.call(stateValue, key)) {
                    arr.push(stateValue[key]);
                  }
                }
                if (indicators.indicatorValues) {
                  setIndicators({
                    ...indicators,
                    indicatorValues: { ...indicators?.indicatorValues, [indicators?.["@type"]]: arr },
                  });
                } else {
                  setIndicators({
                    ...indicators,
                    indicatorValues: { [indicators?.["@type"]]: arr },
                  });
                }
                onClose();
              }}
            />
          </Grid>
        </Grid>
      }
    >
      <Grid sx={{ padding: 1.5, overflow: "auto" }}>{memoizedInputs}</Grid>
    </MuiDialog>
  );
};

type IndicatorPropTypes = {
  open: boolean;
  t: TFunctionArg;
  indicators: any;
  onClose: VoidFunction;
  setIndicators: Dispatch<SetStateAction<any>>;
  setSelectValue?: Dispatch<SetStateAction<any>>;
};

export default IndicatorDialog;
