import { Card } from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import AboutUs from "../../../core/assets/images/icon/about_us_bg.svg";
import AboutUsBg from "../../../core/assets/images/icon/about_us_bg_rectangle.svg";

const useStyles = makeStyles((theme: any) => {
  return {
    custom_p: {
      padding: 2,
      fontSize: "1rem",
      fontFamily: "Poppins",
      lineHeight: "1.5rem",
      [theme.breakpoints.between("2000", "2600")]: {
        fontSize: "1.3rem",
      },
    },
    aboutContainer: {
      alignItems: "center",
      alignSelf: "center",
      justifyContent: "center",
      width: "70%",
      margin: 100,
      border: "1px black",

      [theme.breakpoints.between("1024", "1255")]: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
        paddingLeft: 180,
      },
    },
    cardAbout: {
      borderRadius: 15,
      backgroundColor: "#ffffff",
      padding: 5,

      [theme.breakpoints.between("1024", "1255")]: {
        justifyContent: "flex-end",
        alignItems: "flex-end",
        margin: 5,
      },
    },
    bgContainer: {
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.up("md")]: {
        backgroundImage: `url(${AboutUsBg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "start",
      },

      [theme.breakpoints.up("lg")]: {
        backgroundImage: `url(${AboutUs})`,
        minHeight: "40vh",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
    },
  };
});
const About = () => {
  const classes = useStyles();

  return (
    <Grid id="about" item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "30px", marginBottom: "80px" }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h1 style={{ textAlign: "center" }} className="mt-1">
            About Us
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          justifyContent="center"
          alignItems="center"
          className={classes.bgContainer}
        >
          <Grid item xs={12} sm={12} md={12} lg={9} className={classes.aboutContainer}>
            <Card elevation={3} className={classes.cardAbout} style={{ color: "#000" }}>
              <p className={classes.custom_p}>
                Intellistocks.ai is a product of{" "}
                <a href="https://bigbyteinsights.com/" target="_blank" rel="noreferrer" style={{ color: "#188FF8" }}>
                  Big Byte Insights, Ltd
                </a>
                , a B2B data analytics firm whose products form the basis of investment decisions of some of the largest
                stock investors in the world. Our credibility is our bread and butter. If our analysis is incorrect, we
                will lose our clients and not be able to pay our bills.
              </p>
              <p className={classes.custom_p}>
                Intellistocks.ai is our latest product, which aims to help investors understand what is being discussed
                on social media. We take large amounts of textual data on social media sites, identify stocks that are
                being discussed, and use Natural Language Processing, a subfield of artificial intelligence, linguistics
                and computer science, to determine the sentiment of the discussion. We apply a similar approach to
                analyzing the “Management Discussion and Analysis” section in 10-Q/K filings and “Prepared Remarks”
                section in the quarterly earnings conference calls.
              </p>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default About;
