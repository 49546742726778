import Menu from "@mui/material/Menu";
import { useState, MouseEvent, FC } from "react";
import IconButton from "@mui/material/IconButton";
import { Avatar, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AccountBalanceWallet } from "@mui/icons-material";
import ThemeModeSwitcher from "core/components/themeModeSwitcher";
import deepOrange from "@mui/material/colors/deepOrange";
import { useHistory } from "react-router-dom";

const ITEM_HEIGHT = 45;

const RightSideMenu: FC<RightSideMenuPropTypes> = ({ data, blncError, blncLoading, balance, menu = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { push } = useHistory();

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const renderInitials = (d: any) => {
    const firstName = d?.data?.first_name || "";
    const lastName = d?.data?.last_name || "";

    let FI = "";
    let LI = "";

    if (firstName && firstName.length > 0) {
      FI = firstName.slice(0, 1);
    }
    if (lastName && lastName.length > 0) {
      LI = lastName.slice(0, 1);
    }

    return (FI + LI).toUpperCase();
  };

  return (
    <div>
      <IconButton
        id="long-button"
        aria-label="more"
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={open ? "true" : undefined}
        aria-controls={open ? "long-menu" : undefined}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={open}
        id="long-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "long-button" }}
        PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: "22ch" } }}
      >
        <List>
          {menu ? (
            <>
              <ListItem button onClick={() => push("/")}>
                <ListItemText primary="Home" />
              </ListItem>

              <ListItem button onClick={() => push("/subscription-plans")}>
                <ListItemText primary="Pricing" />
              </ListItem>

              <ListItem button onClick={() => push("/trending-public")}>
                <ListItemText primary="Trending" />
              </ListItem>
            </>
          ) : null}

          {data?.data?.first_name || data?.data?.last_name ? (
            <ListItem button onClick={() => push("/user-profile")}>
              <Grid display="flex" alignItems="center" columnGap={1}>
                {[null, undefined].includes(data?.data?.profile_image) ? (
                  <Avatar
                    children={renderInitials(data)}
                    sx={{ width: "35px", height: "35px", bgcolor: deepOrange[500] }}
                  />
                ) : (
                  <Avatar sx={{ width: "35px", height: "35px" }} alt="profile_pic" src={data?.data?.profile_image} />
                )}

                <Typography>{`${data?.data?.first_name} ${data?.data?.last_name}`}</Typography>
              </Grid>
            </ListItem>
          ) : null}

          <ThemeModeSwitcher withListItemView />
          <ListItem>
            <Grid display="flex" alignItems="center" columnGap={1}>
              <AccountBalanceWallet fontSize="large" />
              {!blncLoading && !blncError && "$" + balance}
            </Grid>
          </ListItem>
        </List>
      </Menu>
    </div>
  );
};

type RightSideMenuPropTypes = {
  data: any;
  blncError: boolean;
  blncLoading: boolean;
  balance: string | number;
  menu?: boolean;
};

export default RightSideMenu;
