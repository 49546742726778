import { FC } from "react";
import { Grid, Skeleton } from "@mui/material";

/**
 *
 * @author Abdul Rauf
 * @modifiedBy Muhammad Raheel
 * @category Components
 * @created Jan 13, 2022
 * @description MUI customized Skeleton/loader for TopTrendingTicker
 * @returns This function returns ReactElement
 */

export const TopTrendingTickerSkeleton: FC<{ numberOfCards?: number }> = ({ numberOfCards = 5 }) => {
  return (
    <>
      {[...Array(numberOfCards)].map((_, index) => (
        <Grid key={index} item xl={2.4} lg={2.4} md={6} sm={6} xs={12}>
          <Skeleton variant="rectangular" height={"10em"} />
        </Grid>
      ))}
    </>
  );
};
