import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import DummyChart from "../../../core/assets/images/icon/dummy_chart.svg";

const LowerHeader = () => {
  const history = useHistory();
  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={0} md={0} lg={1} />
        <Grid item xs={12} sm={12} md={5} lg={4}>
          <p style={{ color: "white", fontSize: "26px" }}>
            The alternative data hub
            <br />
            for the{" "}
            <span
              style={{
                fontSize: "50px",
                fontWeight: 600,
                letterSpacing: "1px",
                color: "white",
                textAlign: "center",
              }}
            >
              Intelligent Investor
            </span>
          </p>

          <div className="mt-4">
            <p style={{ color: "white" }}>
              Signup for a free <span style={{ fontSize: "18px", fontWeight: 600 }}>14 day</span> trial of our premium
              plan.
            </p>
            <button
              style={{
                backgroundColor: " #164B2E",
                borderRadius: "5px",
                padding: "10px 20px",
                color: "#ffffff",
              }}
              className="login_btn"
              onClick={() => history.push("/subscription-plans")}
            >
              {" "}
              Choose Plan{" "}
            </button>
            <small style={{ marginTop: "10px", color: "#fff", display: "block" }}>
              *No credit card required for trial
            </small>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2} />
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <img src={DummyChart} style={{ height: "45vh", width: "100%" }} alt="" />
        </Grid>
      </Grid>
    </>
  );
};

export default LowerHeader;
