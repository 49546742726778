import { FC } from "react";
import { TFunctionArg } from "types/global";
import { withTranslation } from "react-i18next";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { toggleThemeMode } from "redux_config/slices/themeSlice";
import { useAppSelector, useAppDispatch } from "redux_config/hooks";
import { Tooltip, IconButton, ListItem, Typography, Grid } from "@mui/material";

const ThemeModeSwitcher: FC<{ t: TFunctionArg; withListItemView?: boolean }> = ({ t, withListItemView }) => {
  const { mode } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (localStorage.getItem("mode")) {
      localStorage.setItem("mode", mode === "light" ? "dark" : "light");
    } else {
      localStorage.setItem("mode", "light");
    }

    dispatch(toggleThemeMode());
  };

  const themeSwitchContent = (() => {
    return (
      <>
        {mode === "dark" ? (
          <Tooltip title={t("switch_to_light_mode")} arrow>
            <LightModeIcon style={{ height: 35, width: 35 }} />
          </Tooltip>
        ) : (
          <Tooltip title={t("switch_to_dark_mode")} arrow>
            <DarkModeIcon style={{ height: 35, width: 35 }} />
          </Tooltip>
        )}
      </>
    );
  })();

  if (withListItemView) {
    return (
      <ListItem button onClick={handleClick}>
        <Grid display="flex" alignItems="center" columnGap={1}>
          {themeSwitchContent}
          <Typography>Light/Dark Mode</Typography>
        </Grid>
      </ListItem>
    );
  }
  return (
    <IconButton size="medium" onClick={handleClick} sx={{ marginRight: 1 }}>
      {themeSwitchContent}
    </IconButton>
  );
};

export default withTranslation()(ThemeModeSwitcher);
