import * as Yup from "yup";

export const initialValues: InternalStateProps = {
  number: "",
  name: "",
  expiry: "",
  cvc: "",
  issuer: "",
  focused: undefined,
  formData: null,
  address_1: "",
  address_2: "",
  country: "",
};

export const validationSchema = () =>
  Yup.object({
    number: Yup.string().required("Card number is required"),
    name: Yup.string().required("Name is required"),
    expiry: Yup.string()
      .required("Expiry is required")
      .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Invalid expiry date"),
    cvc: Yup.string().required("CVC is required"),
    country: Yup.string().required("Country is required"),
  });
export const validationSchemaEdit = () =>
  Yup.object({
    expiry: Yup.string()
      .required("Expiry is required")
      .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, "Invalid expiry date"),

    country: Yup.string().required("Country is required"),
  });

export type InternalStateProps = {
  cvc: string;
  name: string;
  focused: any;
  formData: any;
  number: string;
  expiry: string;
  issuer: string;
  address_1: string;
  address_2: string;
  country: string | undefined;
};
