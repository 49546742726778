import { makeStyles } from "@mui/styles";
import { useState, Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ReferFriend from "Pages/ReferFriend";
import { DrawerHeader } from "./DrawerHeader";
import MenuIcon from "@mui/icons-material/Menu";
import { adminSidebarList, sidebarList } from "./Sidebar.constant";
import { PowerSettingsNew } from "@mui/icons-material";
import { clearAppTokensAndRedirect } from "core/utils";
import logo from "core/assets/images/logo/intellistocks.png";
import ReferFriendIcon from "../assets/images/icon/refer_friend.svg";
import { Grid, Divider, Drawer, IconButton, List, ListItem, ListItemText } from "@mui/material";
import glassIcon from "../assets/images/icon/glass.svg";

const useStyles = makeStyles({
  paper: {
    background: "#000",
  },
  divider: {
    background: "#fff",
  },
  listItem: {
    "&:hover": {
      background: "#5c5c5c",
    },
  },
});

const SideDrawer = ({ open, userInfo }) => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { pathname } = useLocation();

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const classes = useStyles();
  const sideList = sidebarList();
  const adminList = adminSidebarList();

  const [openReferFriend, setOpenReferFriend] = useState(false);

  const handleClose = () => setOpenReferFriend(false);
  const handleClickOpenReferFriend = () => setOpenReferFriend(true);

  return (
    <>
      <ReferFriend open={openReferFriend} close={handleClose} />
      <IconButton onClick={toggleDrawer("left", true)}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        PaperProps={{ sx: { width: "13em", backgroundColor: "#000" } }}
      >
        <DrawerHeader>
          <img style={{ width: "85%", height: "auto", margin: "auto" }} src={logo} alt="intellistocks" />
        </DrawerHeader>
        <Divider className={classes.divider} />

        <List>
          {pathname && !pathname.includes("/admin-dashboard") ? (
            <>
              {userInfo?.is_staff && (
                <ListItem button className={classes.listItem} component={NavLink} to={"/admin-dashboard/users"}>
                  <img src={glassIcon} height={10} alt="" style={{ color: "#fff", marginLeft: 5, marginRight: 25 }} />
                  <ListItemText primary="Admin Dashboard" style={{ color: "#fff" }} />
                </ListItem>
              )}
              {sideList.map(({ id, label, link, icon }) => (
                <Fragment key={id}>
                  <ListItem
                    button
                    component={NavLink}
                    to={link !== "/" && link}
                    className={classes.listItem}
                    activeStyle={{ backgroundColor: "#2c915a" }}
                    sx={{
                      borderRadius: 18,
                      backgroundColor: id === 2 && pathname === "/idea-generation" ? "#2c915a" : "",
                    }}
                  >
                    <img src={icon} width="20px" height="20px" alt="icon" style={{ marginLeft: 5, marginRight: 25 }} />

                    <ListItemText primary={label} style={{ color: "#fff" }} />
                  </ListItem>
                </Fragment>
              ))}
              <ListItem button onClick={handleClickOpenReferFriend} className={classes.listItem}>
                <img
                  alt=""
                  height={20}
                  src={ReferFriendIcon}
                  style={{ color: "#fff", marginLeft: 5, marginRight: 25 }}
                />
                <ListItemText primary="Refer a Friend" style={{ color: "#fff" }} />
              </ListItem>
              <Grid width={open ? 230 : 61} position="fixed" bottom={20}>
                <ListItem button onClick={clearAppTokensAndRedirect} className={classes.listItem}>
                  <PowerSettingsNew style={{ color: "#fff", marginLeft: 5, marginRight: 25 }} />
                  <ListItemText primary="Logout" style={{ color: "#fff" }} />
                </ListItem>
              </Grid>
            </>
          ) : (
            <>
              {userInfo?.is_staff &&
                adminList.map(({ id, label, link, icon }) => (
                  <Fragment key={id}>
                    <ListItem
                      button
                      component={NavLink}
                      to={link !== "/" && link}
                      sx={{ borderRadius: 18 }}
                      className={classes.listItem}
                      activeStyle={{ backgroundColor: "#2c915a" }}
                    >
                      <img
                        src={icon}
                        width="20px"
                        height="20px"
                        alt="icon"
                        style={{ marginLeft: 5, marginRight: 25 }}
                      />

                      <ListItemText primary={label} style={{ color: "#fff" }} />
                    </ListItem>
                  </Fragment>
                ))}
            </>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default SideDrawer;
