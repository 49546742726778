import { FC } from "react";
import {
  Checkbox,
  FormGroup,
  CheckboxProps,
  FormHelperText,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";

const MuiCheckBox: FC<
  Partial<CheckboxProps & FormControlLabelProps & { label: any; error?: boolean; helperText?: string }>
> = ({ name, label, error, checked, disabled, onChange, required, helperText, color = "primary" }) => (
  <FormGroup>
    <FormControlLabel
      label={label}
      disabled={disabled}
      control={<Checkbox name={name} color={color} checked={checked} onChange={onChange} required={required} />}
    />
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </FormGroup>
);

export default MuiCheckBox;
