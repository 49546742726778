import { lazy, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { getAccessToken, isRefreshTokenExpired } from "core/utils";
import {
  TRENDING,
  WATCH_LIST,
  TO_THE_MOON,
  USER_PROFILE,
  UPGRADE_ACCOUNT,
  ADMIN_DASHBOARD,
  IDEA_GENERATION,
} from "./constants";

/**
 *
 * @author Muhammad Raheel
 * @category Routes
 * @created Mar 14, 2022
 * @description Array of protected routes which serves to AppMain, To define new private/protected route add object in List
 */

export const protectedRoutes: Array<protectedRouteTypes> = [
  {
    path: ADMIN_DASHBOARD,
    component: lazy(() => import("../AdminDashboard")),
  },
  {
    path: TRENDING,
    component: lazy(() => import("../Trending")),
  },
  {
    path: TO_THE_MOON,
    component: lazy(() => import("../ToTheMoon/index")),
  },
  {
    path: WATCH_LIST,
    component: lazy(() => import("../WatchList")),
  },
  {
    path: UPGRADE_ACCOUNT,
    component: lazy(() => import("../UpgradeAccount")),
  },
  {
    path: USER_PROFILE,
    component: lazy(() => import("../UserPages/Profile")),
  },
  {
    path: IDEA_GENERATION,
    component: lazy(() => import("../ToTheMoon/IdeaGeneration")),
  },
];

/**
 *
 * @author Muhammad Raheel
 * @category Routes
 * @created Mar 14, 2022
 * @description type for defining object in protected routes list
 */

export type protectedRouteTypes = {
  path: string;
  component: any;
};

/**
 * @ignore
 */

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  useEffect(() => {
    isRefreshTokenExpired();
  }, []);

  if (getAccessToken()) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Route {...rest} render={() => <Redirect to="/login" />} />;
};
