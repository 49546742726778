import moment from "moment";
import anychart from "anychart";
import AnyChart from "anychart-react";
import Grid from "@mui/material/Grid";
import { notify } from "core/utils";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux_config/hooks";
import { IconButton, Tooltip } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Typography, CircularProgress } from "@mui/material";

const Chart = ({
  data,
  isError,
  chartType,
  interval,
  isLoading,
  indicators,
  showVolume,
  rangePicker,
  height = 700,
  width = "100%",
  publicRoute = false,
}) => {
  const { t } = useTranslation();
  const { mode } = useAppSelector((state) => state.theme);

  const [axisLegend, setaxisLegend] = useState({
    legend1: true,
    legend2: true,
  });
  const { legend1, legend2 } = axisLegend;
  anychart.licenseKey("intellistocks.ai-e22e4a19-c2b6521a");

  let dataTable = anychart.data.table();
  dataTable.addData(data);

  let volumeMapping = dataTable.mapAs({ value: 6 });
  let mentionMapping = dataTable.mapAs({ value: 5 });
  let priceMapping = dataTable.mapAs(
    chartType === "candlestick" ? { open: 1, high: 2, low: 3, close: 4 } : { value: 4 }
  );

  let chart = anychart.stock();
  chart.padding(0, 55, 0, 55);
  var chartLegend = chart.plot().legend();
  chartLegend.title().useHtml(true);
  chartLegend.titleFormat(
    // eslint-disable-next-line
    "<span style='color:#455a64;font-weight:600'>" + "{%value}{dateTimeFormat: dd MMM yyyy'&#44; ' HH:mm}</span>"
  );

  chart.tooltip().title().useHtml(true);
  chart
    .tooltip()
    // eslint-disable-next-line
    .titleFormat("<span style='font-weight:500'>" + "{%x}{dateTimeFormat: dd MMM yyyy'&#44; ' HH:mm}</span>");

  let plot_1 = chart.plot(0);
  plot_1
    .yAxis(0)
    .title()
    .enabled(true)
    .text(t("price"))
    .fontWeight(500)
    .fontSize(".9rem")
    .padding(2)
    .letterSpacing(2)
    .fontColor("rgb(124, 134, 142)");

  plot_1.yAxis(0).width(60);
  plot_1 = plot_1[chartType](priceMapping).name(t("price"));

  plot_1.enabled(legend1);
  if (indicators.indicatorValues && Object.keys(indicators.indicatorValues).length > 0) {
    for (const key in indicators.indicatorValues) {
      if (Object.hasOwnProperty.call(indicators.indicatorValues, key)) {
        chart.plot(0)[key](priceMapping, ...indicators.indicatorValues[key]);
      }
    }
  }

  if (showVolume) {
    const volume = chart.plot(60).column(volumeMapping);
    chart.plot(60).height("30%");
    volume.name(t("volumeM"));
  }

  let extraYScale = anychart.scales.linear();
  let extraYAxis = chart.plot(0).yAxis(1);
  extraYAxis.width(60);
  // extraYAxis.title(t("mentions"));
  extraYAxis
    .title()
    .enabled(true)
    .text(t("mentions"))
    .fontWeight(500)
    .fontSize(".9rem")
    .padding(2)
    .letterSpacing(2)
    .fontColor("rgb(124, 134, 142)");
  extraYAxis.orientation("right");
  extraYAxis.scale(extraYScale);
  let lineSeries = chart.plot(0).line(mentionMapping);

  lineSeries.name(t("mentions"));
  lineSeries.stroke("red");
  lineSeries.yScale(extraYScale);
  lineSeries.enabled(legend2);

  var data_start_date = data.length > 1 && data[1] && data[1][1];
  var data_end_date = data.length > 0 && data[data.length - 1] && data[data.length - 1][0];

  const setLocalStorageObj = () => {
    var find = document.getElementsByClassName("anychart-label-input");

    if (find.length > 0) {
      const from = find[0].value;
      const to = find[1].value;
      var dateObj = new Date(from);
      var dateObjTo = new Date(to);
      // To calculate the time difference of two dates
      var Difference_In_Time = dateObjTo.getTime() - dateObj.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      const obj = { from, to };

      if (obj) {
        localStorage.setItem("date", JSON.stringify(obj));
        localStorage.setItem("noOfDays", Difference_In_Days);
      }
    }
  };
  let noOfDays = localStorage.getItem("noOfDays");
  let dateObjLocal = localStorage.getItem("date");
  // Description: Date calculations for selecting any chart range
  // Author1: Mohsin Iqbal
  // First Date:   2/8/21
  // Present in Module: To The Moon
  if (data.length > 1) {
    if (dateObjLocal) {
      let start = new Date(data_start_date);
      let end = new Date(data_end_date);
      dateObjLocal = JSON.parse(dateObjLocal);
      let dateTo = new Date(dateObjLocal.to);
      let dateFrom = new Date(dateObjLocal.from);
      let isTo = false;
      let isFrom = false;
      if (dateFrom <= end && dateFrom >= start) {
        isFrom = true;
      }
      if (dateTo <= end && dateTo >= start) {
        isTo = true;
      }

      let startRange = moment(dateFrom).format("YYYY MMM DD");
      let endRange = moment(dateTo).format("YYYY MMM DD");
      if (isFrom && isTo) {
        if (interval === "1_day" || interval === "6_hour") {
          endRange = new Date(dateTo.getTime() + 60 * 60 * 24 * 1000);
        } else if (interval === "1_week") {
          endRange = new Date(dateTo.getTime() + 60 * 60 * 24 * 1000 * 7);
        } else if (interval === "1_month") {
          endRange = new Date(dateTo.getTime() + 60 * 60 * 24 * 1000 * 30);
        } else if (interval === "1_hour" || interval === "15_mint") {
          startRange = new Date(dateFrom.getTime() + 60 * 60 * 24 * 1000 * 1);
          endRange = new Date(dateTo.getTime() + 60 * 60 * 24 * 1000 * 1);
        }
        chart.selectRange(startRange, endRange);
      } else if (!isFrom && !isTo) {
        startRange = new Date(data_start_date);
        endRange = new Date(startRange.getTime() + 60 * 60 * 24 * 1000 * noOfDays);
        chart.selectRange(startRange, endRange);
      } else if (isTo && !isFrom) {
        startRange = new Date(data_start_date);
        if (interval === "1_hour" || interval === "15_mint") {
          endRange = new Date(dateTo.getTime() + 60 * 60 * 24 * 1000 * noOfDays);
        } else {
          endRange = new Date(dateTo.getTime() + 60 * 60 * 24 * 1000 * noOfDays);
        }
        chart.selectRange(startRange, endRange);
      }
    } else {
      if (data.length > 18) {
        chart.selectRange(
          data[parseInt(parseInt(data.length) - parseInt(data.length / 12))][0],
          data[parseInt(data.length - 1)][0]
        );
      }
    }
  }

  if (rangePicker) {
    let rangePicker = anychart.ui.rangePicker();
    rangePicker.render(chart);
  }
  chartLegend.listen("legendItemClick", function (e) {
    setLocalStorageObj();
    // eslint-disable-next-line
    if (e.itemIndex == 0) {
      setaxisLegend({ ...axisLegend, legend1: !legend1 });
      // eslint-disable-next-line
    } else if (e.itemIndex == 1) {
      setaxisLegend({ ...axisLegend, legend2: !legend2 });
      // eslint-disable-next-line
    }
  });

  return (
    <>
      {!publicRoute && (
        <Grid container justifyContent={"flex-end"} width="95%">
          <Tooltip title="Save chart date range">
            <IconButton
              onClick={() => {
                setLocalStorageObj();
                notify("success", "Date range saved successfully");
              }}
              sx={{ cursor: "pointer" }}
            >
              <DateRangeIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}

      <div className="chart-mainContainer">
        {isLoading ? (
          <div style={{ position: "absolute", left: "50%", top: "48%" }}>
            <CircularProgress size={50} />
            <Typography variant="h6" sx={{ marginLeft: -1 }}>
              {t("loading")}
            </Typography>
          </div>
        ) : isError ? (
          <h4>Error</h4>
        ) : (
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <AnyChart
              width={width}
              height={height}
              credits={false}
              instance={chart}
              background={mode === "dark" && "#383838"}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Chart);
