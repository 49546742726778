import axios, { AxiosError } from "axios";
import LocalStorageService from "api_hooks/localStorageService";

const apiURL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

const localStorageService = LocalStorageService.getService();

const RequestManager = axios.create({
  baseURL: apiURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Adds request interceptor
RequestManager.interceptors.request.use(
  (config: any) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = `JWT ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//Adds response interceptor
RequestManager.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const axiosError = error as AxiosError;
    const originalRequest = axiosError.config;

    if (error.response.status === 401 && originalRequest.url === "/auth/jwt/refresh/") {
      localStorageService.clearToken();
      // window.location.href = window.location.origin + "/login";
      window.location.href = window.location.origin + "/";
      return Promise.reject(error);
    }

    if (error.response.status === 401 && originalRequest.url !== "/auth/jwt/create/") {
      const refreshToken = localStorageService.getRefreshToken();
      return RequestManager.post("/auth/jwt/refresh/", {
        refresh: refreshToken,
      })
        .then((res) => {
          if (res.status === 200) {
            localStorageService.setAccessToken(res.data.access);
            RequestManager.defaults.headers.common["Authorization"] = "JWT" + localStorageService.getAccessToken();
            return RequestManager(originalRequest);
          }
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    }

    return Promise.reject(error);
  }
);

export default RequestManager;
