import { memo } from "react";
// import Page404 from "Pages/404";
import Layout from "core/layout/Layout";
import LandingPage from "Pages/LandingPage";
import { Route, Switch } from "react-router-dom";
import { AdminRoute, adminRoutes } from "Pages/routes/admin";
import { PublicRoute, publicRoutes } from "Pages/routes/public";
import { PrivateRoute, protectedRoutes } from "Pages/routes/private";

const renderPublicRoutes = (() => {
  return publicRoutes.map((Obj, index) => <PublicRoute exact key={index} path={Obj.path} component={Obj.component} />);
})();

const renderProtectedRoutes = (() => {
  return protectedRoutes.map((Obj, index) => (
    <PrivateRoute exact key={index} path={Obj.path} component={Obj.component} />
  ));
})();

const renderAdminRoutes = (() => {
  return adminRoutes.map((Obj, index) => <AdminRoute exact key={index} path={Obj.path} component={Obj.component} />);
})();

/**
 *
 * @author Muhammad Raheel
 * @category Routes
 * @created Jan 13, 2022
 * @description Main entry points for all routes
 * @returns It renders private and protected routes of application
 */

export const AppMain = () => {
  return (
    <Switch>
      <Route exact path="/" component={() => <LandingPage />} />
      {/* <Route path="*" component={() => <Page404 />} /> */}

      {renderPublicRoutes}

      <Layout>
        {renderProtectedRoutes}
        {renderAdminRoutes}
      </Layout>
    </Switch>
  );
};

export default memo(AppMain);
