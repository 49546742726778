import RequestManager from "./lib/axios";
import ReactPixel from "react-facebook-pixel";

/**
 *
 * @author Abdul Rauf
 * @category Services
 * @created Jan 13, 2022
 * @modifiedBy Muhammad Raheel
 * @lastModificationDate jan 27, 2022
 * @description MUI customized button element
 */

export const _service = {
  postUser: (data: object) => RequestManager.post(`/auth/users/`, data),
  Login: (data: { username: string; password: string }) => {
    let mutateData: { email?: string; username: string; password: string } = data;
    mutateData.email = mutateData.username;
    mutateData.username = mutateData.email.split("@")[0];

    return RequestManager.post(`/auth/jwt/create/`, data);
  },
  RefreshToken: (data: object) => RequestManager.post(`/auth/jwt/refresh`, data),
  ResetPassword: (data: object) => RequestManager.post(`/auth/users/reset_password/`, data),
  ResetPasswordConfirm: (data: object) => RequestManager.post(`/auth/users/reset_password_confirm/`, data),
  ChangePassword: (data: object) => RequestManager.post(`/auth/users/change_password/`, data),
  ActivateUser: (data: object) => RequestManager.post(`/auth/users/activation/`, data),
  ResendActivation: (data: object) => RequestManager.post(`/auth/users/resend_activation/`, data),
  UserInfo: () => RequestManager.get(`/auth/users/me/`),
  UpdateUserInfo: (data: object | File) => RequestManager.put(`/auth/users/me/`, data),
  SocialSignUp: (data: object) => RequestManager.post(`/auth/social_signup/`, data),
  GetTickers: () => RequestManager.get(`/data/tickers/`),
  SearchTickers: (queryString: string = "") => RequestManager.get(`/data/ticker_search/?ticker=${queryString}`),
  GetDataSources: () => RequestManager.get(`/data/data_sources/`),
  AddToWatchList: (data: { company: string; ticker: string }) => RequestManager.post(`/data/watch_list/`, data),
  getWatchList: (sourceId: string | number) => RequestManager.get(`/data/watch_list_new/?source=${sourceId}`),
  getToTheMoon: (ticker: string, interval: string, source: string | number) =>
    RequestManager.get(`/data/to_the_moon/?ticker=${ticker}&interval=${interval}&source=${source}`),
  GetTwitterOAuthToken: () => RequestManager.get(`/auth/get_twitter_oauth_token/`),
  GetTrendingData: ({ queryKey }: any) => {
    let query_string = "";
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, source, average_mentions, market_cap] = queryKey;
    query_string = `?source=${source}&average_mentions=${average_mentions || ""}&market_cap=${market_cap || ""}`;
    return RequestManager.get(`/data/trends/${query_string}`);
  },
  deleteWatchListRecord: (id: string | number) => RequestManager.delete(`/data/watch_list/${id}/`),
  updateWatchListRecord: (data: any) => {
    return RequestManager.put(`/data/watch_list/${data?.id}/`, data, { params: { source: data?.source?.id } });
  },
  addCoinsInWatchListCSV: (data: { data: any; replace: boolean }) => {
    return RequestManager.post("/data/bulk_wl_tickers_create/", data.data, {
      params: { replace: data.replace },
      headers: { "Content-Type": `multipart/form-data` },
    });
  },
  getTickers: (param: string | undefined) => {
    if (param) {
      return RequestManager.get("/data/watch_list_search/", { params: { coin: param } });
    }
    return RequestManager.get("/data/watch_list_search/");
  },
  getTickersSearch: (param: string | undefined) => {
    if (param) {
      return RequestManager.get(`/data/coin_search/?coin=${param}`);
    }
    return RequestManager.get("/data/coin_search/");
  },
  getGraphData: (params: any, publicRoute: boolean) => {
    const url = publicRoute ? "data/graph_data/" : "data/graph/";

    return RequestManager.get(url, {
      params: {
        interval: params?.interval,
        ticker: params?.ticker,
        source: params?.source,
        market_cap: params?.market_cap,
        average_mentions: params?.average_mentions,
      },
    });
  },
  addNewTickerToWatchList: (data: string[]) => RequestManager.put("/data/bulk_wl_tickers_create/", data),
  getTopTrends: ({ queryKey }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, source, average_mentions, market_cap] = queryKey;
    let requestURL;

    requestURL = `/data/trend_top_trends_new/`;

    return RequestManager.get(requestURL, {
      params: {
        source: source,
        market_cap: market_cap ? market_cap : "",
        average_mentions: average_mentions ? average_mentions : "",
      },
    });
  },
  getTopTrendsWatchlist: ({ queryKey }: any) => {
    const [, source] = queryKey;
    let requestURL;
    requestURL = `/data/watch_list_top_trends_new/`;
    return RequestManager.get(requestURL, {
      params: {
        source: source,
      },
    });
  },
  getUserBillingHistory: () => RequestManager.get(`/payments/billing_history/`),
  getUserPaymentInfo: () => RequestManager.get(`/payments/payment_info/`),
  postUserPaymentInfo: (data: object) => RequestManager.post(`/payments/payment_info_api/`, data),
  putEditUserPaymentInfo: (data: object) => RequestManager.put(`/payments/payment_info/`, data),
  getUserBalance: () => RequestManager.get(`/payments/get_balance/`),
  postSubscription: (data: any) => {
    try {
      if (process.env.NODE_ENV === "production") {
        ReactPixel.track("Subscribe", {
          value: data.price_id,
          currency: "USD",
          content_ids: [data.product],
          content_type: "service",
          content_name: data.product,
        });
      }
      //@ts-ignore
      window.dataLayer.push({
        event: data.plan_id,
      });
    } catch (err) {
      console.log(err);
    }

    if (data?.isSubscription) {
      return RequestManager.put("/payments/subscription_plans/", data);
    } else {
      return RequestManager.post("/payments/subscription_plans/", data);
    }
  },
  getSubscriptionPlans: () => RequestManager.get("/payments/subscription_plans/"),
  getUserInfo: () => RequestManager.get("/auth/users/me/"),
  setPrimaryCard: (data: object) => RequestManager.post("/payments/set_primary_card/", data),
  putUserPaymentInfo: (data: object) => RequestManager.put("/payments/set_primary_card/", data),
  deleteUserPaymentInfo: (data: object) => RequestManager.delete("/payments/payment_info/", { data }),
  getIgQuarterList: () => RequestManager.get("/data/ig_quarter_list_new/"),
  getIdeaGeneration: ({ queryKey }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, year, qtr, source, variable, market_cap, p] = queryKey;
    let requestURL;
    requestURL = `/data/idea_generation/`;
    return RequestManager.get(requestURL, {
      params: {
        p,
        source,
        year,
        qtr,
        market_cap,
        variable,
      },
    });
  },
  subscribePlan: (data: object) => RequestManager.post("/payments/subscription_plans/", data),
  deleteSubscription: () => RequestManager.delete(`/payments/subscription/`),
  updateStocks: (data: any) => {
    const params = { price_id: data?.price_id, quantity: data?.quantity };
    if (data?.isSubscription) {
      return RequestManager.put(`/payments/subscription_plans/`, params);
    } else {
      return RequestManager.post(`/payments/subscription_plans/`, params);
    }
  },
  postTrial: (data: { email: string; price_id: string; quantity: number; trialing: boolean }) =>
    RequestManager.post(`/payments/subscription_plans/`, data),
  getUserGetReferCode: () => RequestManager.get(`/affiliate/get_refer_code/`),
  getUsersAdminDashboard: () => RequestManager.get(`/auth/api/get_users/`),
  addUsersAdminDashboard: (data: object) => RequestManager.post(`/auth/api/get_users/`, data),
  updateUsersAdminDashboard: (data: any) => {
    return RequestManager.put(`/auth/api/get_users/${data?.id}/`, data, { params: { source: data?.source?.id } });
  },
  deleteUsersAdminDashboard: (id: string | number) => {
    return RequestManager.delete(`/auth/api/get_users/${id}/`);
  },
  //
  getGroupsAdminDashboard: () => RequestManager.get(`/auth/api/get_groups/`),
  addGroupsAdminDashboard: (data: object) => RequestManager.post(`/auth/api/get_groups/`, data),
  updateGroupsAdminDashboard: (data: any) => {
    return RequestManager.put(`/auth/api/get_groups/${data?.id}/`, data, { params: { source: data?.source?.id } });
  },
  deleteGroupsAdminDashboard: (id: string | number) => {
    return RequestManager.delete(`/auth/api/get_groups/${id}`);
  },
  getUserGroupsAdminDashboard: () => RequestManager.get(`/auth/api/get_groups_users/`),
  addUsersGroupsAdminDashboard: (data: object) => RequestManager.post(`/auth/api/get_groups_users/`, data),
  updateUsersGroupsAdminDashboard: (data: any) => {
    return RequestManager.put(`/auth/api/get_groups_users/${data?.id}/`, data, {
      params: { source: data?.source?.id },
    });
  },
  deleteUsersGroupsAdminDashboard: (id: string | number) => {
    return RequestManager.delete(`/auth/api/get_groups_users/${id}`);
  },
  getGroupPermissions: () => RequestManager.get(`/auth/api/get_groups_permissions/`),
  updateGroupPermissions: (data: any) => {
    return RequestManager.put(`/auth/api/change_groups_permissions/${data?.id}/`, data, {
      params: { source: data?.source?.id },
    });
  },
  getGroupPermissionsList: () => RequestManager.get(`/auth/api/get_permissions/`),
  getPublicDataSource: () => RequestManager.get("/data/data_multiple_sources/"),
  getPublicTrendingData: ({ queryKey }: any) => {
    let query_string = "";
    // eslint-disable-next-line
    const [, source, average_mentions, market_cap] = queryKey;
    query_string = `?source=${source}&average_mentions=${average_mentions || ""}&market_cap=${market_cap || ""}`;
    return RequestManager.get(`/data/trends_data/${query_string}`);
  },

  updateContactNumberOPT: (formData: any) => RequestManager.post(`/auth/user_contact_update/`, formData),

  updateContactNumberCode: (formData: any) => RequestManager.put(`/auth/user_contact_update/`, formData),
};
