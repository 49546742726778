import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, useMediaQuery } from "@mui/material";
// import discord from "../../../core/assets/images/icon/discord.svg";
import LogoHere from "../../../core/assets/images/icon/white_logo.svg";
import TwitterIcon from "../../../core/assets/images/icon/twitterIcon.png";
import FacebookIcon from "../../../core/assets/images/icon/facebookIcon.png";
import LinkedinIcon from "../../../core/assets/images/icon/linkedInIcon.png";

const useStyles = makeStyles((theme: any) => ({
  heading: {
    margin: "0px",
    color: "white",
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    fontFamily: "Poppins !important",
  },
  bg_landing: {
    width: "60%",
    minHeight: "400px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },

  more_about: {
    padding: "0px 200px",
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 20px",
    },
  },
  socialMedia: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  socialMediaLink: {
    color: "white",
    fontSize: "16px",
    fontFamily: "Poppins !important",
    textDecoration: "underline",
  },
  socialMediaIcon: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
}));

const legalLink = [
  { link: "/privacy-policy", label: "Privacy Policy" },
  { link: "/terms-and-conditions", label: "Terms and Conditions" },
  { link: "/disclaimer", label: "Disclaimer" },
  { link: "cookies-policy", label: "Cookies Policy" },
];
const tabs1 = [
  { id: "#about", label: "About" },
  { id: "#faqs", label: "FAQs" },
];

const socialMediaIcon = [
  {
    icon: FacebookIcon,
    link: "https://www.facebook.com/Intellistocksai-103109445309771",
  },
  {
    icon: LinkedinIcon,
    link: "https://www.linkedin.com/company/intellistocks-ai",
  },
  { icon: TwitterIcon, link: "https://twitter.com/intellistocksai" },
];

const Footer = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down(400));
  const isXxs = useMediaQuery(theme.breakpoints.down(350));
  const isMd = useMediaQuery(theme.breakpoints.between(944, 1244));

  return (
    <Grid xs={12} sm={12} md={12} lg={12} sx={{ backgroundColor: "#164B2E", marginTop: "30px" }}>
      <Grid container padding={5}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <img src={LogoHere} width={isMd ? 230 : isXxs ? 180 : isXs ? 250 : 300} alt="logo" />
        </Grid>

        <Grid item xs={3} sm={3} md={4} lg={3}>
          <Typography className={classes.heading}>Legal</Typography>
          {legalLink.map(({ link, label }, index) => (
            <Link
              to={link}
              key={index}
              target="_blank"
              color="secondary"
              component={NavLink}
              className={classes.socialMediaLink}
            >
              <span style={{ color: "#fff" }}>{label}</span> <br />
            </Link>
          ))}
        </Grid>

        <Grid item xs={3} sm={3} md={4} lg={3}>
          <Typography className={classes.heading} mb={1}>
            Misc
          </Typography>

          {tabs1.map(({ id, label }, index) => (
            <Link key={index} href={id} className={classes.socialMediaLink}>
              <span style={{ color: "#fff" }}>{label}</span> <br />
            </Link>
          ))}
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={3}>
          {/* <Grid container alignItems="center" columnGap={1}>
            <Grid item mt={-2}>
              <Typography className={classes.heading}>For support, please join us on Discord </Typography>
            </Grid>
            <Grid item>
              <a href="https://discord.gg/WrbA5Bj58y" target="_blank" rel="noreferrer">
                <img src={discord} alt="discord" />
              </a>
            </Grid>
          </Grid> */}

          <Typography className={classes.heading}>Follow us</Typography>
          {socialMediaIcon.map((media, i) => (
            <a key={i} href={media.link} target="_blank" rel="noreferrer">
              <img
                width={30}
                src={media.icon}
                alt="social media"
                style={{ cursor: "pointer", marginLeft: media.icon === FacebookIcon ? "0px" : "15px" }}
              />
            </a>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
