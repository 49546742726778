import { useState } from "react";
import { useQuery } from "react-query";
import { Grid, Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { MuiDialog } from "core/components";
import { _service } from "api_hooks/services";
import { GoogleLogin } from "react-google-login";
import InputAdornment from "@mui/material/InputAdornment";
import Google from "../../core/assets/images/icon/googleAuth3.svg";
import GoogleIcon from "../../core/assets/images/icon/google-icon.svg";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { ClickAwayListener, OutlinedInput, Typography } from "@mui/material";

const ReferFriend = (props: any) => {
  const [open, setOpen] = useState(false);

  async function copyTextToClipboard() {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(props.referralLink);
    } else {
      return document.execCommand("copy", true, props.referralLink);
    }
  }

  const handleTooltipOpen = () => {
    copyTextToClipboard()
      .then(() => {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const { data } = useQuery(["getUserGetReferCode"], _service.getUserGetReferCode);

  return (
    <>
      <MuiDialog
        fullWidth
        maxWidth="md"
        open={props.open}
        onClose={props.close}
        title="Refer a Friend"
        disableBackDropClickEscapeKey
      >
        <Grid px={10} textAlign="center">
          <img src={GoogleIcon} alt="google" />

          <Typography mt={3} fontSize={14}>
            Win <span style={{ fontWeight: "bold" }}>$15</span> in credit if you get someone to subscribe to our paid
            plan. Please click below to let your friends know how to generate alpha with{" "}
            <Link target="_blank" rel="noreferrer" href="https://intellistocks.ai/">
              intellistocks.ai
            </Link>
          </Typography>

          <Typography mt={2} mb={0.3} align="left" fontSize={14}>
            Either invite friends using this link
          </Typography>

          <div style={{ maxHeight: "60px", textAlign: "center" }}>
            <OutlinedInput
              size="small"
              value={data?.data.signup_link}
              id="outlined-adornment-password"
              style={{ width: "100%", padding: 5, cursor: "pointer" }}
              inputProps={{ style: { fontFamily: "poppins", color: "grey", fontWeight: "light" } }}
              endAdornment={
                <InputAdornment position="end">
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(data?.data.signup_link);
                      }}
                    >
                      <Tooltip
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        onClose={handleTooltipClose}
                        title="Copied to clipboard!"
                        PopperProps={{ disablePortal: true }}
                      >
                        <FileCopyOutlinedIcon style={{ cursor: "pointer" }} onClick={handleTooltipOpen} />
                      </Tooltip>
                    </div>
                  </ClickAwayListener>
                </InputAdornment>
              }
            />
          </div>

          <Typography my={1.5}>or email directly only from here.</Typography>

          <GoogleLogin
            buttonText="Login"
            isSignedIn={false}
            prompt={"consent"}
            fetchBasicProfile={false}
            scope="https://www.googleapis.com/auth/contacts.readonly"
            clientId="785749838580-ln49opadr4883i6l6kplc1pftpa3f22c.apps.googleusercontent.com"
            onSuccess={(data) => {
              if (data) {
                const params = {};
                props.getEmails(params);
              }
            }}
            onFailure={() => console.log("Reject")}
            render={(renderProps) => (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={Google}
                  alt="google"
                  onClick={renderProps.onClick}
                  style={{ cursor: "pointer", width: "40%" }}
                />
              </div>
            )}
          />
        </Grid>
      </MuiDialog>
    </>
  );
};

export default ReferFriend;
