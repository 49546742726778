import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid } from "@mui/material";
import AboutBG from "../../../core/assets/images/icon/green_about_bg.svg";
import TotalCharts from "../../../core/assets/images/icon/total_charts.png";

const useStyles = makeStyles((theme: any) => ({
  bg_landing: {
    width: "60%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  about: {
    marginTop: "30px",
    marginBottom: "80px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "220px !important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "120px",
    },
  },
  more_about: {
    padding: "0px 200px",
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 20px",
    },
  },
  GridReverse: {
    [theme.breakpoints.down("xs")]: {
      order: -1,
      textAlign: "center",
    },
  },
  ChartImg: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "85% ",
      align: "center",
      margin: "auto",
      display: "none",
    },
  },
  CardMobile: {
    width: "70%",
    marginLeft: "17%",
    marginTop: "-269px ",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      width: "85%",
      marginTop: "-260px ",
      marginLeft: "42px",
    },
    [theme.breakpoints.down("md")]: {
      width: "85%",
      marginTop: "-260px ",
      marginLeft: "42px",
    },
    [theme.breakpoints.down("xl")]: {
      width: "85%",
      marginTop: "-350px ",
      marginLeft: "42px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "85%",
      marginTop: "-210px ",
      marginLeft: "42px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "85%",
      margin: "auto",
    },
    [theme.breakpoints.between("2000", "2600")]: {
      marginTop: "-497px",
    },
  },
  custom_p: {
    fontSize: "1rem",
    lineHeight: "1.8rem",
    [theme.breakpoints.between("2000", "2600")]: {
      fontSize: "1.3rem",
    },
  },
}));

const ProductsDesc = () => {
  const classes = useStyles();

  return (
    <>
      <Grid id="about" item xs={12} sm={12} md={12} lg={12} className={classes.about}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <img src={AboutBG} alt="about" className={`${classes.bg_landing}`} />
            <Card className={classes.CardMobile}>
              <CardContent>
                <p className={classes.custom_p}>
                  You can use Intellistocks.ai to monitor the blogosphere and to generate alpha in two ways:
                </p>
                <ol className={classes.custom_p}>
                  <li>
                    <p className={classes.custom_p}>
                      Use our alerts either to protect against potential downside risk due to coordinated actions on
                      various subreddits (including WallStreetBets) or to trade with the crowd.
                    </p>
                  </li>
                  <li>
                    <p className={classes.custom_p}>
                      Use our API data to create momentum or mean reversion trading strategies.
                    </p>
                  </li>
                </ol>
                <p className={classes.custom_p}>
                  You can also use Intellistocks' secondary features for idea generation purposes. These features
                  include analysis of earnings conference calls, 10K and 10Q filings, using Natural Language Processing,
                  which is a subfield of Artificial Intelligence.
                </p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className={classes.GridReverse}>
            <img src={TotalCharts} alt="chart" className={classes.ChartImg} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductsDesc;
