import {
  Ar,
  Au,
  As,
  Az,
  Bh,
  Bd,
  By,
  Be,
  Br,
  Kh,
  Ca,
  Cn,
  Cr,
  Hr,
  Cy,
  Cz,
  Dk,
  Ee,
  Fi,
  Fr,
  De,
  Hk,
  In,
  It,
  Jp,
  Kr,
  La,
  Lv,
  Lu,
  Mo,
  My,
  Np,
  Nl,
  Nz,
  No,
  Om,
  Pk,
  Ph,
  Pl,
  Pt,
  Pr,
  Qa,
  Ro,
  Ru,
  Sm,
  Sa,
  Rs,
  Sg,
  Sk,
  Si,
  Es,
  Lk,
  Se,
  Ch,
  Tw,
  Tj,
  Th,
  Tr,
  Tm,
  Us,
  Gb,
  Uz,
  Vn,
} from "react-flags-select";

export const TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";

export const COUNTRIES = [
  {
    value: "AR",
    label: "Armenia",
    Flag: Ar,
  },
  {
    value: "AU",
    label: "Australia",
    Flag: Au,
  },
  {
    value: "AS",
    label: "Austria",
    Flag: As,
  },
  {
    value: "AZ",
    label: "Azerbaijan",
    Flag: Az,
  },
  {
    value: "BB",
    label: "Bahrain",
    Flag: Bh,
  },
  {
    value: "BN",
    label: "Bangladesh",
    Flag: Bd,
  },
  {
    value: "BE",
    label: "Belarus",
    Flag: By,
  },
  {
    value: "BG",
    label: "Belgium",
    Flag: Be,
  },
  {
    value: "BR",
    label: "Brazil",
    Flag: Br,
  },
  {
    value: "CA",
    label: "Cambodia",
    Flag: Kh,
  },
  {
    value: "CN",
    label: "Canada",
    Flag: Ca,
  },
  {
    value: "CH",
    label: "China",
    Flag: Cn,
  },
  {
    value: "CR",
    label: "Costa Rica",
    Flag: Cr,
  },
  {
    value: "CO",
    label: "Croatia",
    Flag: Hr,
  },
  {
    value: "CY",
    label: "Cyprus",
    Flag: Cy,
  },
  {
    value: "CP",
    label: "Czech Republic",
    Flag: Cz,
  },
  {
    value: "DN",
    label: "Denmark",
    Flag: Dk,
  },
  {
    value: "ES",
    label: "Estonia",
    Flag: Ee,
  },
  {
    value: "FI",
    label: "Finland",
    Flag: Fi,
  },
  {
    value: "FR",
    label: "France",
    Flag: Fr,
  },
  {
    value: "GE",
    label: "Germany",
    Flag: De,
  },
  {
    value: "HK",
    label: "Hong Kong",
    Flag: Hk,
  },
  {
    value: "IN",
    label: "India",
    Flag: In,
  },
  {
    value: "IT",
    label: "Italy",
    Flag: It,
  },
  {
    value: "JA",
    label: "Japan",
    Flag: Jp,
  },
  {
    value: "KO",
    label: "Korea",
    Flag: Kr,
  },
  {
    value: "LO",
    label: "Laos",
    Flag: La,
  },
  {
    value: "LA",
    label: "Latvia",
    Flag: Lv,
  },
  {
    value: "LU",
    label: "Luxembourg",
    Flag: Lu,
  },
  {
    value: "MA",
    label: "Macao",
    Flag: Mo,
  },
  {
    value: "ML",
    label: "Malaysia",
    Flag: My,
  },
  {
    value: "NP",
    label: "Nepal",
    Flag: Np,
  },
  {
    value: "NL",
    label: "Netherlands",
    Flag: Nl,
  },
  {
    value: "NZ",
    label: "New Zealand",
    Flag: Nz,
  },
  {
    value: "NO",
    label: "Norway",
    Flag: No,
  },
  {
    value: "OM",
    label: "Oman",
    Flag: Om,
  },
  {
    value: "PK",
    label: "Pakistan",
    Flag: Pk,
  },
  {
    value: "PH",
    label: "Philippines",
    Flag: Ph,
  },
  {
    value: "PO",
    label: "Poland",
    Flag: Pl,
  },
  {
    value: "PT",
    label: "Portugal",
    Flag: Pt,
  },
  {
    value: "PR",
    label: "Puerto Rico",
    Flag: Pr,
  },
  {
    value: "QA",
    label: "Qatar",
    Flag: Qa,
  },
  {
    value: "RO",
    label: "Romania",
    Flag: Ro,
  },
  {
    value: "RF",
    label: "Russian Federation",
    Flag: Ru,
  },
  {
    value: "SM",
    label: "San Marino",
    Flag: Sm,
  },
  {
    value: "SA",
    label: "Saudi Arabia",
    Flag: Sa,
  },
  {
    value: "SE",
    label: "Serbia",
    Flag: Rs,
  },
  {
    value: "SI",
    label: "Singapore",
    Flag: Sg,
  },
  {
    value: "SK",
    label: "Slovakia",
    Flag: Sk,
  },
  {
    value: "SN",
    label: "Slovenia",
    Flag: Si,
  },
  {
    value: "SP",
    label: "Spain",
    Flag: Es,
  },
  {
    value: "SL",
    label: "Sri Lanka",
    Flag: Lk,
  },
  {
    value: "SW",
    label: "Sweden",
    Flag: Se,
  },
  {
    value: "SZ",
    label: "Switzerland",
    Flag: Ch,
  },
  {
    value: "TA",
    label: "Taiwan",
    Flag: Tw,
  },
  {
    value: "TK",
    label: "Tajikistan",
    Flag: Tj,
  },
  {
    value: "TH",
    label: "Thailand",
    Flag: Th,
  },
  {
    value: "TU",
    label: "Turkey",
    Flag: Tr,
  },
  {
    value: "TR",
    label: "Turkmenistan",
    Flag: Tm,
  },
  {
    value: "UK",
    label: "United Kingdom",
    Flag: Gb,
  },
  {
    value: "US",
    label: "United States",
    Flag: Us,
  },
  {
    value: "UZ",
    label: "Uzbekistan",
    Flag: Uz,
  },
  {
    value: "VI",
    label: "Vietnam",
    Flag: Vn,
  },
];
export const variableOptions = [
  {
    name: "Mentions - Sentiment Negative",
    value: "mentions-sentiment_negative",
  },
  {
    name: "Mentions - Sentiment Positive",
    value: "mentions-sentiment_positive",
  },
  {
    name: "Mentions - Sentiment Neutral",
    value: "mentions-sentiment_neutral",
  },
  {
    name: "Mentions - Sentiment Compound",
    value: "mentions-sentiment_compound",
  },
  {
    name: "SEC 10K/10Q MD&A - Total Words",
    value: "sec_10k_10q_md_a-total_words",
  },
  {
    name: "SEC 10K/10Q MD&A - Smog Index",
    value: "sec_10k_10q_md_a-smog_index",
  },
  {
    name: "SEC 10K/10Q MD&A - Flesch Kincaid Grade",
    value: "sec_10k_10q_md_a-flesch_kincaid_grade",
  },
  {
    name: "SEC 10K/10Q MD&A - Gunning Fog",
    value: "sec_10k_10q_md_a-gunning_fog",
  },
  {
    name: "SEC 10K/10Q MD&A - Sentiment Negative",
    value: "sec_10k_10q_md_a-sentiment_negative",
  },
  {
    name: "SEC 10K/10Q MD&A - Sentiment Positive",
    value: "sec_10k_10q_md_a-sentiment_positive",
  },
  {
    name: "SEC 10K/10Q MD&A - Sentiment Neutral",
    value: "sec_10k_10q_md_a-sentiment_neutral",
  },
  {
    name: "SEC 10K/10Q MD&A - Sentiment Compound",
    value: "sec_10k_10q_md_a-sentiment_compound",
  },
  {
    name: "Earnings Call Prepared Remarks - Total Words",
    value: "earnings_call_prepared_remarks-total_words",
  },
  {
    name: "Earnings Call Prepared Remarks - Smog Index",
    value: "earnings_call_prepared_remarks-smog_index",
  },
  {
    name: "Earnings Call Prepared Remarks - Flesch Kincaid Grade",
    value: "earnings_call_prepared_remarks-flesch_kincaid_grade",
  },
  {
    name: "Earnings Call Prepared Remarks - Gunning Fog",
    value: "earnings_call_prepared_remarks-gunning_fog",
  },
  {
    name: "Earnings Call Prepared Remarks - Sentiment Negative",
    value: "earnings_call_prepared_remarks-sentiment_negative",
  },
  {
    name: "Earnings Call Prepared Remarks - Sentiment Positive",
    value: "earnings_call_prepared_remarks-sentiment_positive",
  },
  {
    name: "Earnings Call Prepared Remarks - Sentiment Neutral",
    value: "earnings_call_prepared_remarks-sentiment_neutral",
  },
  {
    name: "Earnings Call Prepared Remarks - Sentiment Compound",
    value: "earnings_call_prepared_remarks-sentiment_compound",
  },
];
export const mentionsList = [
  "mentions-sentiment_negative",
  "mentions-sentiment_positive",
  "mentions-sentiment_neutral",
  "mentions-sentiment_compound",
];
