import {
  ADMIN_DASHBOARD_USERS,
  ADMIN_DASHBOARD_USERS_GROUPS,
  ADMIN_DASHBOARD_USERS_TO_GROUPS,
  ADMIN_DASHBOARD_GROUP_PERMISSIONs,
} from "./constants";
import { lazy, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { getAccessToken, isRefreshTokenExpired } from "core/utils";

export const adminRoutes: Array<adminRouteTypes> = [
  // {
  //   path: ADMIN_DASHBOARD,
  //   exact: true,
  //   component: lazy(() => import("../AdminDashboard")),
  // },
  {
    path: ADMIN_DASHBOARD_USERS,
    component: lazy(() => import("../AdminDashboard/AD_Users")),
  },
  {
    path: ADMIN_DASHBOARD_USERS_GROUPS,
    component: lazy(() => import("../AdminDashboard/AD_UsersGroups")),
  },
  {
    path: ADMIN_DASHBOARD_USERS_TO_GROUPS,
    component: lazy(() => import("../AdminDashboard/AD_UsersToGroups")),
  },
  {
    path: ADMIN_DASHBOARD_GROUP_PERMISSIONs,
    component: lazy(() => import("../AdminDashboard/AD_GroupPermissions")),
  },
];

export type adminRouteTypes = {
  path: string;
  component: any;
};

/**
 * @ignore
 */

export const AdminRoute = ({ component: Component, ...rest }: any) => {
  useEffect(() => {
    isRefreshTokenExpired();
  }, []);

  if (getAccessToken()) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  return <Route {...rest} render={() => <Redirect to="/admin-dashboard" />} />;
};
