import { _service } from "api_hooks/services";
import { queryClient } from "QueryClient.config";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export const useUserInfo = () => {
  return useQuery("user_info", _service.UserInfo);
};
export const useUserBillingHistory = () => {
  return useQuery("user_billing_hist", () => _service.getUserBillingHistory());
};
export const useUserPaymentInfo = () => {
  return useQuery("user_payment_info", () => _service.getUserPaymentInfo());
};
export const useUserBalance = () => {
  return useQuery("user_balance", () => _service.getUserBalance());
};

export const useUpdateProfileInfo = () => {
  return useMutation((data: any) => _service.UpdateUserInfo(data), {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("user_info");
      toast.success("Profile updated successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || error?.message);
    },
  });
};

export const useAddUserPaymentInfo = (handleClose: VoidFunction) => {
  return useMutation((data: any) => _service.postUserPaymentInfo(data), {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("user_payment_info");
      queryClient.invalidateQueries("Me");
      toast.success("User payment info added successfully");
      handleClose();
    },
    onError: (error: any) => {
      if (Array.isArray(error?.response?.data)) {
        toast.error(error?.response?.data?.join("\n"));
      } else {
        toast.error(error?.message);
      }
      handleClose();
    },
  });
};
export const useEditUserPaymentInfo = (handleClose: VoidFunction) => {
  return useMutation((data: any) => _service.putEditUserPaymentInfo(data), {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("user_payment_info");
      toast.success("User payment info updated successfully");
      handleClose();
    },
    onError: (error: any) => {
      if (Array.isArray(error?.response?.data)) {
        toast.error(error?.response?.data?.join("\n"));
      } else {
        toast.error(error?.message);
      }
      handleClose();
    },
  });
};

export const useSetPrimaryCard = () => {
  return useMutation((data: { card_id: string }) => _service.setPrimaryCard(data), {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("user_payment_info");
      toast.success("Primary card updated successfully");
    },
    onError: (error: any) => {
      if (Array.isArray(error?.response?.data)) {
        toast.error(error?.response?.data?.join("\n"));
      } else {
        toast.error(error?.message);
      }
    },
  });
};

export const usePutUserPaymentInfo = () => {
  return useMutation((data: { card_id: string }) => _service.putUserPaymentInfo(data), {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("user_payment_info");
      toast.success("User payment info updated successfully");
    },
    onError: (error: any) => {
      if (Array.isArray(error?.response?.data)) {
        toast.error(error?.response?.data?.join("\n"));
      } else {
        toast.error(error?.message);
      }
    },
  });
};

export const useDeleteUserPaymentInfo = () => {
  return useMutation(
    (data: { card_id: string }) => {
      return _service.deleteUserPaymentInfo(data);
    },
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries("user_payment_info");
        toast.success("User payment info deleted successfully");
      },
      onError: (error: any) => {
        if (Array.isArray(error?.response?.data)) {
          toast.error(error?.response?.data?.join("\n"));
        } else {
          toast.error(error?.message);
        }
      },
    }
  );
};
